import AllAttendanceReportDownload from "./allAttendanceReportDownload";
import AttendanceReportSummaryGraph from "./attendanceReportSummaryGraph";
import AttendanceReportSummaryTable from "./attendanceReportSummaryTable";


const AttendanceReportVisualSection = () =>{

    return(
        <>
        <div className="theme-content-left-head">
                <h2>Attendance Report</h2>
            </div>
            <br />
            <hr />
            <br />
            <h3>Attendance Summary Statistics Table </h3>
            <br />
            <br />
            <AttendanceReportSummaryTable />
            <br />
            <hr />
            <br />
            <br />
            <div className="row">
                <div className="col-6">
                    <AttendanceReportSummaryGraph />
                </div>
                <div className="col-6">
                    <AllAttendanceReportDownload />
                </div>
            </div>
        </>
    )
}

export default AttendanceReportVisualSection;