import React, { useRef, useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend, PieController, CategoryScale, LinearScale, Title } from 'chart.js';
import { DEMOGRAPHICS_REPORT_GRAPH } from '../../apis/apis';
import SisLoader from '../../widgets/loader';

Chart.register(ArcElement, Tooltip, Legend, PieController, CategoryScale, LinearScale, Title);

const DemographicsGraphReports = () => {
    const [loader, set_loader] = useState(false);
    const [labels, set_labels] = useState([]);
    const [data_set, set_data_set] = useState([]);

    const DEMOGRAPHICS_REPORT_GRAPH_VIEW = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await DEMOGRAPHICS_REPORT_GRAPH(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_labels(API_RESPONSE?.data?.labels);
            set_data_set(API_RESPONSE?.data?.data_set);
        }
        set_loader(false);
    };

    useEffect(() => {
        DEMOGRAPHICS_REPORT_GRAPH_VIEW();
    }, []);

    const chartRef = useRef(null);

    useEffect(() => {
        if (labels.length === 0 || data_set.length === 0) {
            return;
        }

        const ctx = chartRef.current.getContext('2d');

        const data = {
            labels: labels,
            datasets: [
                {
                    // label: labels,
                    data: data_set,
                    backgroundColor: [
                        '#FF6384', // International Students
                        '#36A2EB', // Domestic Students
                        '#FF0000', // Regular Students
                        '#50C878', // Athletic Students
                        '#9966FF', // Male
                        '#FF9F00', // Female
                        '#C9CBCF', // Others
                        '#FF11FF'  // Racial Diversity
                    ],
                    hoverBackgroundColor: [
                        '#FF6384', // International Students
                        '#36A2EB', // Domestic Students
                        '#FF0000', // Regular Students
                        '#50C878', // Athletic Students
                        '#9966FF', // Male
                        '#FF9F00', // Female
                        '#C9CBCF', // Others
                        '#FF11FF'  // Racial Diversity
                    ]
                }
            ]
        };

        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            boxWidth: 12, // Set your desired width for the color bar here
                            font: {
                              size: 12 // Set your desired font size here
                          }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Student Demographics Report'
                    }
                }
            },
        };

        const myPieChart = new Chart(ctx, config);

        return () => {
            myPieChart.destroy();
        };
    }, [labels, data_set]);

    return (
        <div>
            <h1>Demographics Reports</h1>
            {loader ? (
                <SisLoader />
            ) : (
                <>
                    {labels?.length > 0 && data_set?.length > 0 ? (
                        <div style={{ width: '100%', margin: '0 auto' }}>
                            <canvas ref={chartRef}></canvas>
                        </div>
                    ) : (
                        <p style={{textAlign:"center", color:"red"}}>No Demographics Report Found.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default DemographicsGraphReports;
