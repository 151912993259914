import React from 'react'
import { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Input, Popconfirm, Select, Spin, Table, notification, Row } from 'antd';
import { CloseOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined, StopOutlined } from '@ant-design/icons';

import {

  CALENDARS_LIST,
  SEMESTER_REGISTRATION_VIEW,
  BILLING_STUDENT_LIST,

} from "../../apis/apis";


import SisLoader from '../../widgets/loader';

import { useNavigate, useParams } from "react-router-dom";



function Billing() {

  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [calendars_list, set_calendars_list] = useState([]);
  const [semesters_list, set_semesters_list] = useState([]);
  const [active_calender_id, set_active_calender_id] = useState();
  const [active_sem_id, set_active_sem_id] = useState();

  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'create') {
      check_permissions = permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  
  const [table_list, set_table_list] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = table_list.filter(
      (item) => {
        var name = item.enrollment_details.middle_name === 'null' && !item.enrollment_details.middle_name ? item.enrollment_details.first_name + ' ' + item.enrollment_details.last_name : item.enrollment_details.first_name + ' ' + item.enrollment_details.middle_name + ' ' + item.enrollment_details.last_name;
        if (name.toLowerCase().includes(query.toLowerCase()) || item.enrollment_details.application_no.toLowerCase().includes(query.toLowerCase()) ||  item.enrollment_details.phone.toLowerCase().includes(query.toLowerCase()) ||  item.enrollment_details.primary_email.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }
      }
    );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      setFilteredData(filtered);
    }
  };


  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const Calendar_API_RESPONSE = await CALENDARS_LIST(FORM_DATA);

    if (Calendar_API_RESPONSE?.data?.status) {
      // set_loader(false);
      set_calendars_list(Calendar_API_RESPONSE?.data?.calendars);
      set_active_calender_id(Calendar_API_RESPONSE?.data?.calendars[0]?.id)
      set_semesters_list(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters)
      set_active_sem_id(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      if(Calendar_API_RESPONSE?.data?.calendars[0]?.id && Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id)
      {
        REGISTER_LIST_API(Calendar_API_RESPONSE?.data?.calendars[0]?.id,Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      }

    } else {
      // set_loader(false);
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "student_id",
      key: "id",
    },
    {
      title: "Name",
      render: (text, record) => {
        if (record.student_status) {
          return (
            <>
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => navigate('/genrate-billing/' + btoa(active_calender_id) + '/' + btoa(active_sem_id) + '/' + btoa(record.student_id)) } >
                {record.enrollment_details.first_name +' '}
                {record.enrollment_details.middle_name === 'null' || record.enrollment_details.middle_name === null ? ' ' : record.enrollment_details.middle_name + ' '}
                {record.enrollment_details.last_name}
              </span>
            </>

          );
        } else {
          return (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate('/genrate-billing/')}>
                {record.enrollment_details.first_name}
                {record.enrollment_details.middle_name === 'null' ? ' ' : record.enrollment_details.middle_name}
                {record.enrollment_details.last_name}
                <span
                  style={{
                    backgroundColor: "red",
                    fontSize: "10px",
                    position: "relative",
                    color: "#fff",
                    padding: "2px 5px",
                    top: "-6px",
                    left: "3px",
                    borderRadius: "5px",
                  }}
                >
                  New
                </span>
              </span>
            </>
          );
        }

      },


    },

    {
      title: "Application No",
      dataIndex: "application_no",
      key: "application_no",
      render: (text, record) => {

        return <> {record.enrollment_details.application_no}</>;

      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => {

        return <> {record.enrollment_details.phone}</>;

      },
    },
    {
      title: "Email",
      dataIndex: "primary_email",
      key: "primary_email",
      render: (text, record) => {

        return <> {record.enrollment_details.primary_email}</>;

      },

    },
    {
      title: "Health insurance",
      dataIndex: "health_insurance",
      key: "health_insurance",
      render: (text, record) => {

        return <> {record?.health_insurance?.health_insurance_status == 1 ? 'Active' : 'Inactive'}</>;

      },

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {

        return <>
         {!record.semester_billing_status && 'Ready for Payment'}
         {record.semester_billing_status?.payment_status == 2 && 'Paid'}
         {record.semester_billing_status?.payment_status == 3 && 'Partial Paid'}
         {record.semester_billing_status?.payment_status == 4 && 'Not Paid'}
        </>;

      },
    },

  ];

  const REGISTER_LIST_API = async (calender_id,sem_id) => {
     set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", calender_id);
    FORM_DATA.append("semester_id", sem_id);
  //  const API_RESPONSE = await SEMESTER_REGISTRATION_VIEW(FORM_DATA);
  //  BILLING_STUDENT_LIST
    const API_RESPONSE = await BILLING_STUDENT_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.student_billing);
      setFilteredData(API_RESPONSE?.data?.student_billing);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    LIST_API();
  }, []);

  // useEffect(() => {
  //   REGISTER_LIST_API();
  // }, [active_sem_id]);

  const generate_sem_list = (item) => {
    set_semesters_list(item?.semesters);
    set_active_calender_id(item?.id);
    set_active_sem_id(item.semesters[0]?.id);
    REGISTER_LIST_API(item?.id,item.semesters[0]?.id);

  }

  const generate_students_list = (sem_detail) => {
    set_active_sem_id(sem_detail?.id);
    REGISTER_LIST_API(sem_detail?.calendar_id,sem_detail?.id);
  }
  return (
    <>
    {loader ? (
            <SisLoader />
          ) : (
      <>
      <div className="theme-content-left-head">
        <h3>Billing Module</h3>
      </div>
      <div className="theme-content-head">
        <div className="sis-tab-menu">
          {calendars_list.length > 0 && (
            <>
              {calendars_list.map((item, index) => (
                <>
                  <>
                    {(active_calender_id == item?.id) ?
                      <>
                        <span className="active" >{item?.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_sem_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          )}
        </div>
       
      </div>
      <div className="sis-tab-sem-menu">
          {semesters_list?.length > 0 &&
            <>
              {semesters_list.map((item, index) => (
                <>
                  <>
                    {(active_sem_id == item.id) ?
                      <>
                        <span className="active" >{item.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_students_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          }
        </div>
      <div>
        <div className="input-box" style={{ maxWidth: "250px", marginTop: "30px" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
        <div className="sis-tab-content" style={{ marginTop: "50px" }}  >
        <Table columns={columns} dataSource={filteredData} />
        </div>
      </div>
      </>
    )}
    </>
  )
}
export default Billing

































































// /* eslint-disable no-unused-vars */
// import React, { useEffect, useRef, useState } from "react";
// import {
//     // Button,
//     // Checkbox,
//     // Input,
//     // Popconfirm,
//     // Select,
//     // Spin,
//     Table,
//     // notification,
// } from "antd";
// // import "./style.css";
// // import type { TableColumnsType, TableProps } from "antd";
// // import {
// //     CloseOutlined,
// //     CloudUploadOutlined,
// //     DeleteOutlined,
// //     EditOutlined,
// //     EyeInvisibleOutlined,
// //     EyeTwoTone,
// //     LoadingOutlined,
// //     SmileOutlined,
// //     StopOutlined,
// // } from "@ant-design/icons";
// import {
//     // ADD_Calendar,
//     // ADD_USER,
//     CALENDARS_LIST,
//     // CATEGORIES_LIST,
//     // DELETE_CALENDAR,
//     // ROLE_LIST,
//     // STATUS_CALENDAR,
// } from "../../apis/apis";
// // import SisLoader from "../../widgets/loader";
// import Item from "antd/es/list/Item";
// import { useNavigate, useParams } from "react-router-dom";
// // import { Editor } from "@tinymce/tinymce-react";
// import { render } from "@testing-library/react";
// import { SearchOutlined } from "@ant-design/icons";

// const SemesterRegistration = () => {
//     const navigate = useNavigate();


//     const [loader, set_loader] = useState(true);
//     // const [errors, set_errors] = useState([  ROLE_LIST]);

//     // Form State
//     // const [title, set_title] = useState("");
//     const [Spin, set_Spin] = useState();
//     const [table_list, set_table_list] = useState([]);


//     const columns = [
//         {
//             title: "Name",
//             dataIndex: "name",
//             key: "name",
//         },
//         {
//             title: "Programme",
//             dataIndex: "programme",
//             key: "programme",
//         },
//         {
//             title: "Student status",
//             dataIndex: "student status",
//             key: "student status",
//         },
//         {
//             title: "Action",
//             dataIndex: "action",
//             key: "action",
//         },



//     };
//     useEffect(() => {
//         LIST_API();
//     }, [loader]);
//     return (
//         <>
//             <div>
//                 <div className="theme-content-head">
//                     <div className="theme-content-left-head">
//                         <h3>Billing</h3>
//                     </div>

//                     <div className="theme-content-right-head"></div>
//                 </div>
//                 <div className="sis-tab-menu">
//                     <span onClick={() => navigate("/edit-calendar/" + id)}>
//                         Academic Calendar
//                     </span>
//                     <span className="active" onClick={() => navigate("/calendar-semester/" + id)}>Semesters</span>
//                     <span onClick={() => navigate("/calendar-schedule/" + id)}>
//                         Schedules
//                     </span>
//                 </div>







//                 {/* <div className="theme-content-right-head"> </div>
//                 </div>
//                 <div className="academic">
//                     <span>Academic Calendar 2023</span>
//                     <span>Academic Calendar 2024</span>
//                     <span>Academic Calendar 2025</span>
//                 </div>

//                 <div className="fall-spring-summer">
//                     <span>Fall 24</span>
//                     <span>Spring 24</span>
//                     <span>Summer 24</span>
//                 </div> */}

//                 <div className="searchtable">
//                     <span>Search table </span>
//                     <span>   <input type="text" placeholder="Search... ">  <SearchOutlined /></input></span>
//                 </div>

//                 <div className="sis-tab-content">
//                     <Table columns={columns} dataSource={table_list} />
//                 </div>
//             </div>
//         </>
//     );
// };
// export default SemesterRegistration;
