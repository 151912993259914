import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import { InputNumber } from "antd";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { ADD_ROOM, EDIT_ROOM, UPDATE_ROOM } from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";

const EditRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [page_loader, set_page_loader] = useState(true);

  const [errors, set_errors] = useState([]);

  const buildings_list = ["Main_Building", "Library_Building", "Athletics_Building", "DI_Building", "Labs"];

  const floors_list = {
    Main_Building: ["Basement", "1st floor", "2nd floor", "3rd floor",  "4th floor"],
    Library_Building: ["1st floor", "2nd floor"],
    Athletics_Building: ["1st floor", "2nd floor"],
    DI_Building: ["1st floor", "2nd floor"],
    Labs: ["DI Lab", "Computer Lab", "Library Lab"],
  };

  // Form State
  const [roomname, set_roomname] = useState("");
  const [roomnumber, set_roomnumber] = useState("");
  const [building, set_building] = useState("");

  const [floor, set_floor] = useState(floors_list[buildings_list[0]]);
  const [secondfloor, set_secondfloor] = useState("");

  const [capacity, set_capacity] = useState("");
  const [roomtype, set_roomtype] = useState("");
  const [inputs, setInputs] = useState([{ assetname: "", quantity: "" }]);

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);

    const EDIT_API_RESPONSE = await EDIT_ROOM(FORM_DATA);

    if (EDIT_API_RESPONSE?.data?.status) {
      set_roomname(EDIT_API_RESPONSE?.data?.room.room_name);
      set_roomnumber(EDIT_API_RESPONSE?.data?.room.room_number);
      set_building(EDIT_API_RESPONSE?.data?.room.building_name);
      set_secondfloor(EDIT_API_RESPONSE?.data?.room.floor);
      set_capacity(EDIT_API_RESPONSE?.data?.room.capacity);
      set_roomtype(EDIT_API_RESPONSE?.data?.room.room_type);
      setInputs(EDIT_API_RESPONSE?.data?.room.room_assets);
      set_page_loader(false);
    }
  };

  useEffect(() => {
    VIEW_API();
  }, []);

  const handleAddInput = () => {
    setInputs([...inputs, { assetname: "", quantity: "" }]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const onbuildingChange = (value) => {
    set_building(value);
    set_floor(floors_list[value]);
    set_secondfloor(floors_list[value][0]);
  };

  let assets = JSON.stringify(inputs);

  const handlecopy = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("room_name", roomname);
    FORM_DATA.append("room_number", roomnumber);
    FORM_DATA.append("building_name", building);
    FORM_DATA.append("floor", secondfloor);
    FORM_DATA.append("capacity", capacity);
    FORM_DATA.append("room_type", roomtype);
    FORM_DATA.append("room_assets", assets);
    const API_RESPONSE = await UPDATE_ROOM(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Room Successfully updated",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/rooms-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  
  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Edit Room</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}

            <div className="row">
              <div className="col-12">
                <div className="input-box">
                  <label htmlFor="roomname">
                    Room Name
                  </label>
                  <Input
                    value={roomname}
                    placeholder="room name"
                    id="roomname"
                    onChange={(e) => set_roomname(e.target.value)}
                  />
                  {errors?.room_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.room_name[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="roomnumber">
                    Room Number<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={roomnumber}
                    placeholder="room number"
                    id="roomnumber"
                    onChange={(e) => set_roomnumber(e.target.value)}
                  />
                  {errors?.room_number && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.room_number[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="Select-Building">
                    Select Building<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                    placeholder="Select Building"
                    value={building}
                    onChange={onbuildingChange}
                    style={{ width: "100%" }}
                    options={buildings_list.map((item) => ({
                      value: item,
                      label: item.replace('_', " "),
                    }))}
                  />

                  {errors?.building_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.building_name[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="Select-Floor">
                    Select Floor<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                   {console.log(floor)}
                  <Select
                    placeholder="Select Floor"
                    value={secondfloor}
                    onChange={(value) => set_secondfloor(value)}
                    style={{ width: "100%" }}
                    options={floor.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />

                  {errors?.floor && (
                    <>
                      <span style={{ color: "red" }}>{errors?.floor[0]}</span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="Select-Floor">
                    Enter Student Capacity (Number Only)
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>

                  <InputNumber
                   
                    placeholder="quantity"
                    value={capacity}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    style={{ maxWidth: "150px" }}
                    onChange={(value) => set_capacity(value)}
                  />
                  <br></br>
                  {errors?.capacity && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.capacity[0]}
                      </span>
                    </>
                  )}
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <Radio.Group
                    onChange={(e) => set_roomtype(e.target.value)}
                    value={parseInt(roomtype)}
                  >
                    <Radio value={1}>Lecture Room</Radio>
                    <Radio value={2}>Auditorium</Radio>
                    <Radio value={3}>Lab</Radio>
                  </Radio.Group>
                  <br></br>
                  {errors?.room_type && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.room_type[0]}
                      </span>
                    </>
                  )}
                </div>

                <label htmlFor="roomassets">
                  Room Assets
                </label>

                <div className="container"  style={{display:"flex", flexDirection:"column"}}>
                  {inputs.map((item, index) => (
                    <div
                      className="input-box row"
                      style={{ marginBottom: "8px", marginTop: "4px" }}
                      key={index}
                    >
                      <div className="col-4">
                        <Input
                          name="assetname"
                          placeholder="asset name"
                          value={item.assetname}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors?.assetname && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.assetname[0]}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="col-4">
                        <Input
                          name="quantity"
                          placeholder="quantity"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={item.quantity}
                          onCopy={handlecopy}
                          onPaste={handlecopy}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors?.quantity && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.quantity[0]}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="col-4">
                        {inputs.length > 1 && (
                          <Button
                          style={{width:"90px",height:"40px"}}
                            onClick={() => handleDeleteInput(index)}
                            danger
                          >
                            Delete
                          </Button>
                        )}
                        {index === inputs.length - 1 && (
                          <Button
                           className='lusisbtn'
                          style={{width:"90px",height:"40px",marginLeft: "10px"}}
                            onClick={() => handleAddInput()}
                            type="primary"
                          ><PlusOutlined/>
                            Add
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12">
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary"  className='lusisbtn'>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Saving
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={UPDATE_API}  className='lusisbtn'>
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditRoom;
