import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useState } from "react";

export default function GradeListTable({
  semester,
  transferredCourses,
  grades_courses,
  total_credit_attempted_txn,
  total_credit_earn_txn,
  total_credit_given
}) {
  const [show_form, set_show_form] = useState(false);

  const columnsTXN = [
    {
      title: "Courses",
      dataIndex: "courses_title",
      key: "courses_title",
    },
    {
      title: "Courses Code",
      dataIndex: "courses_code",
      key: "courses_code",
    },
    {
      title: "Institution Name",
      dataIndex: "transferred_course_transferred_institution",
      key: "transferred_course_transferred_institution",
    },
    {
      title: "Attempted credit",
      dataIndex: "transferred_course_credit_attempted",
      key: "transferred_course_credit_attempted",
    },
    {
      title: "Earned credit",
      dataIndex: "transferred_course_credit_earn",
      key: "transferred_course_credit_earn",
    },
    {
      title: "Grade",
      render: (text, record) => {
        return <>CR</>;
      },
    },
  ];
  const columnsG = [
    {
      title: "Courses",
      dataIndex: "courses_title",
      key: "courses_title",
    },
    {
      title: "Courses Code",
      dataIndex: "courses_code",
      key: "courses_code",
    },
    {
      title: "Total credit",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Given credits",
      dataIndex: "given_credits",
      key: "given_credits",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
  ];
  return (
    <>
      <div className="sis-section">
        <div className="sis-section-head">
          <h4> {semester.semester_title}</h4>
          <div>
            {show_form ? (
              <b onClick={() => set_show_form(false)}>
                <DownOutlined />{" "}
              </b>
            ) : (
              <b onClick={() => set_show_form(true)}>
                {" "}
                <UpOutlined />{" "}
              </b>
            )}
          </div>
        </div>

        {show_form && (
          <>
            <div className="sis-section-container">
              {transferredCourses.length > 0 && (
                <>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      margin: "5px 0px",
                      padding: "10px",
                    }}
                  >
                    <h3>Transferred courses</h3>
                    <Table
                      columns={columnsTXN}
                      dataSource={transferredCourses}
                    />
                    <h5>Total Credit  Attempted = {total_credit_attempted_txn} </h5>
                    <h5>Total Earned  Credit = {total_credit_earn_txn} </h5>
                  </div>
                </>
              )}

              <div
                style={{
                  backgroundColor: "#fff",
                  margin: "5px 0px",
                  padding: "10px",
                }}
              >
                <h3>Courses</h3>
                <Table columns={columnsG} dataSource={grades_courses} />
                <h5>Total Credit  Given = {total_credit_given} </h5>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
