import { useState, useEffect } from "react";

import "../../Attendance/attendance.css";
import { Input, Select, Button, Table, Popconfirm, notification, Spin } from "antd";
import { DeleteOutlined, LoadingOutlined, PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ADD_ATTENDANCE_CATEGORY, ADD_REASON, UPDATE_ATTENDANCE_CATEGORY, VIEW_ATTENDANCE_CATEGORY } from "../../../apis/apis";
import Reasons from "./reasons";
import SisLoader from "../../../widgets/loader";
function AttendanceCategory() {
  const navigate = useNavigate();
  const history = useLocation();
  const { id } = useParams();
  const [errors, set_errors] = useState([]);
  const [table_loader, set_table_loader] = useState(true);
  //  Category Add
  const [page_loader, set_page_loader] = useState(false);
  const [reason_row_loader, set_reason_row_loader] = useState(false);
  const [title, set_title] = useState("");
  const [status, set_status] = useState(1);
  const [category_loader, set_category_loader] = useState(false);
  // Reasons State 
  const [reason_loader, set_reason_loader] = useState(true);
  const [reason_button_loader, set_reason_button_loader] = useState(false);
  const [add_save_button, set_add_save_button] = useState(true);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  const ADD_ATTENDANCE_CATEGORY_API = async () => {
    set_reason_button_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("title", title);
    FORM_DATA.append("status", status);
    const API_RESPONSE = await ADD_ATTENDANCE_CATEGORY(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Category Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/attendance-category/" + btoa(API_RESPONSE?.data?.id));
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_reason_button_loader(false);
    }
  };
  const ADD_REASON_API = async () => {
    set_reason_button_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("attendance_category_id", atob(id));
    const API_RESPONSE = await ADD_REASON(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Reasons Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_reason_button_loader(false);
      set_table_loader(false)
      // window.location = "/attendance-category/" + id;
      navigate("/attendance-category/" + id);
    } else {
      set_table_loader(false)
      set_errors(API_RESPONSE.data.errors);
      set_reason_button_loader(false);
    }
  };
  const UPDATE_ATTENDANCE_CATEGORY_API = async () => {
    set_category_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("title", title);
    FORM_DATA.append("status", status);
    const API_RESPONSE = await UPDATE_ATTENDANCE_CATEGORY(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Category Successfully Updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_category_loader(false);
      navigate("/attendance-category/" + btoa(API_RESPONSE?.data?.id));
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_category_loader(false);
    }
  };
  // Attendance Category
  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);
    const RESPONSE = await VIEW_ATTENDANCE_CATEGORY(FORM_DATA);
    if (RESPONSE?.data?.status) {
      set_title(RESPONSE?.data?.category?.title);
      set_status(RESPONSE?.data?.category?.status);
      set_reason_loader(false)
      set_reason_button_loader(false);
    
    } else {
      set_errors(RESPONSE.data.errors);
      set_reason_loader(false)
      set_reason_button_loader(false);
     
    }
  }
  useEffect(() => {
    if (id) {
      VIEW_API()
      set_add_save_button(false)
    } else {
      set_reason_loader(false)
      
    }
  }, [history, reason_row_loader])
  return (
    <>
      {page_loader ? <SisLoader /> : <>
        <div>
          <div className="theme-content-left-head">
            <h3>{id ? 'View' : 'Add'} Attendance Category</h3>
          </div>
          <br />
          {errors?.try && (
            <>
              <span style={{ color: "red" }}>{errors?.try[0]}</span>
            </>
          )}
          {errors?.catch && (
            <>
              <span style={{ color: "red" }}>{errors?.catch[0]}</span>
            </>
          )}
         
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "300px" }}>
                <label>Category Name *</label>
                <Input placeholder="Category Name" value={title} onChange={(e) => set_title(e.target.value)} />
                {errors?.title && (
                  <>
                    <span style={{ color: "red" }}>{errors?.title[0]}</span>
                  </>
                )}
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label>Status</label>
                <Select value={status} onChange={(value) => set_status(value)} style={{ width: "100%" }} placeholder="--Status--">
                  <Select.Option value={1}>Active</Select.Option>
                  <Select.Option value={0}>Inactive</Select.Option>

                </Select>
                {errors?.status && (
                  <>
                    <span style={{ color: "red" }}>{errors?.status[0]}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          
          <div>
            
            {category_loader ? <>
              {check_permissions('attendance_categories', 'update') &&
              <Button className="lusisbtn" type="primary" style={{ marginRight: "10px" }} disabled>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} /> Save
              </Button>}
              {check_permissions('attendance_categories', 'update') &&
              <Button className="lusisbtn" type="primary" disabled>Cancel</Button>}
            </> :
              <>
                {!add_save_button ? <>
                  {check_permissions('attendance_categories', 'update') &&
                  <Button className="lusisbtn" type="primary" style={{ marginRight: "10px" }} onClick={UPDATE_ATTENDANCE_CATEGORY_API}>Save
                  </Button>}
                  {check_permissions('attendance_categories', 'update') &&
                  <Button className="lusisbtn" type="primary" onClick={() => navigate('/attendence-setup')}>Cancel</Button>}
                </> : <>
                  {reason_button_loader ? <>
                    {check_permissions('attendance_categories', 'create') &&
                    <Button className="lusisbtn" type="primary" style={{ marginRight: "10px" }} disabled>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} /> Save
                    </Button>}
                    {check_permissions('attendance_categories', 'create') &&
                    <Button className="lusisbtn" type="primary" disabled>Cancel</Button>}
                  </> : <>
                  {check_permissions('attendance_categories', 'create') &&
                    <Button className="lusisbtn" type="primary" style={{ marginRight: "10px" }} onClick={ADD_ATTENDANCE_CATEGORY_API}> Save
                    </Button>}
                    {check_permissions('attendance_categories', 'create') &&
                    <Button className="lusisbtn" type="primary"  onClick={() => navigate('/attendence-setup')}>Cancel</Button>}

                  </>}
                </>}
              </>
            }

          </div>
          <br />
          {(check_permissions('attendance_category_reasons', 'create') || check_permissions('attendance_category_reasons', 'update')) &&
           <div>
             <div style={{ float:"right", position:"relative", bottom:"-26px" }}>
             {id ? <>
            {reason_button_loader ? <>
            <Button className="lusisbtn" style={{width:"156px"}} type="primary" size="small" disabled><Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} />Add Reasons</Button></> : 
            <Button className="lusisbtn" style={{width:"156px"}} type="primary" onClick={ADD_REASON_API}><PlusOutlined/>Add Reasons</Button>}
          </> : <>
            
          </>}

             </div>
           </div>}

          <br />
          <br />
          {check_permissions('attendance_category_reasons', 'view') &&
          <div className="theme-content-left-head">
            <h3
              style={{
                color: " rgba(142, 142, 142, 1)",
              }}
            >
              Reasons
            </h3>
          </div>}
          <br />
          {/* {loader ? <SisLoader /> : */}
          {id ? 
          <>
            {check_permissions('attendance_category_reasons', 'view') &&
            <Reasons history={history} reason_row_loader={reason_row_loader} set_reason_row_loader={set_reason_row_loader} attendance_category_id={atob(id)} table_loader={table_loader} />}
          </> : <>
          
          <div className="reason-row reason-head">
            <div className="reason-column">Reason</div>
            <div className="reason-column">Status</div>
            <div className="reason-column">Faculty Access</div>
            <div className="reason-column">Type</div>
            <div className="reason-column">Excused/ Unexcused</div>
            <div className="reason-column">Use in calculation</div>
            <div className="reason-column">Full Day / Half Day</div>
            <div className="reason-column">Action</div>
          </div>
          <p style={{color:"red", fontSize:"16px", textAlign:"center"}}>Empty Data</p>
          </>}

          {/* } */}
          <br />
         
        </div>
      </>}
    </>
  );
}

export default AttendanceCategory;
