import { Button, Select, Tooltip } from "antd"
import { useEffect, useState } from "react";
import { CALENDARS_LIST, STUDENT_BY_SEMESTER_ATTENDANCE } from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import AttendanceGenerateReportTable from "./attendanceGenerateReportTable";
import { FilePdfOutlined } from "@ant-design/icons";
import { BACKEND_URL } from "../../../config/config";



const AttendanceGenerateReport = () => {
    const [loader, set_loader] = useState(false);
    const [academic_year, set_academic_year] = useState("");
    const [student, set_student] = useState("");
    const [student_list, set_student_list] = useState([]);
    const [course, set_course] = useState("");
    const [errors, set_errors] = useState([]);
    const [academic_year_list, set_academic_year_list] = useState([]);
    const [semester, set_semester] = useState("");
    const [sem_list, set_sem_list] = useState([]);
    const [calender_index, set_calender_index] = useState(0)
    const navigate = useNavigate();

const STUDENT_LIST_BY_SEMESTER_ATTENDANCE = async(sem_id) =>{
    set_student('');
    set_errors('');
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('semester_id', sem_id);
    const API_RESPONSE = await STUDENT_BY_SEMESTER_ATTENDANCE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
        set_student_list(API_RESPONSE?.data?.students)
        set_loader(false);
    }
    else {
        set_errors(API_RESPONSE?.data?.errors)
        set_loader(false);
    }
    set_loader(false);
}



    const LIST_API = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_academic_year_list(API_RESPONSE?.data?.calendars);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }


    const calender_change_function = (value) => {
        set_semester('');
        set_errors('');
        const index = academic_year_list.findIndex(item => item.id === value)
        set_academic_year(value);
        set_calender_index(index);
        set_sem_list(academic_year_list[index].semesters);
    }

    useEffect(() => {
        LIST_API();
    }, [academic_year]);


    const handleErrors = (errors) => {
        set_errors(errors);
    }

    return (
        <>
            <h2>Generate Attendance Reports</h2>
            <div className="row">
                <div className="col-3">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Academic Year<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />
                        <Select
                            placeholder="--Select Academic Year--"
                            style={{ width: "100%", height: "40px" }}
                            onChange={calender_change_function}
                            value={academic_year ? parseInt(academic_year) : academic_year}
                            options={academic_year_list.map((item) => ({
                                value: item.id,
                                calender_id: item.id,
                                label: item.title,
                            }))}

                        />
                        {errors?.entering_year && (
                            <>
                                <span style={{ color: "red" }}>
                                    Please Select Academic Year
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Semester<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />

                        <Select
                            placeholder="--Select Semester--"
                            style={{ width: "100%", height: "40px" }}
                            disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                            onChange={(value) => {
                                set_semester(value);
                                STUDENT_LIST_BY_SEMESTER_ATTENDANCE(value);
                            }}
                            value={semester}
                            options={sem_list.map((item) => ({
                                value: item?.id,
                                label: item?.title,
                            }))}
                        />

                        {errors?.semester_id && (
                            <>
                                <span style={{ color: "red" }}>
                                The semester field is required.
                                </span>
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="col-3">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Course
                        </label>
                        <br />

                        <Select
                            placeholder="--Select Course--"
                            style={{ width: "100%", height: "40px" }}
                            onChange={(value) => {
                                set_course(value);
                            }}
                            value={course}
                        //   options={sem_list.map((item) => ({
                        //         value: item.id,
                        //         label: item.title,
                        //       }))}
                        />
                    </div>
                </div> */}
                <div className="col-3">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Student<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />
                        <Select
                            placeholder="--Select Student--"
                            disabled={Boolean ? !semester : semester}
                            style={{ width: "100%", height: "40px" }}
                            onChange={(value) => {
                                set_errors('');
                                set_student(value);
                            }}
                            value={student}
                          options={student_list.map((item) => ({
                                value: item?.id,
                                label:  item?.first_name + 
                                (item?.middle_name && item?.middle_name !== "null" ? " " + item?.middle_name : "") + 
                                " " + item?.last_name,
                              }))}
                        />
                          {errors?.student_id && (
                            <>
                                <span style={{ color: "red" }}>
                                The student field is required.
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <AttendanceGenerateReportTable semester={semester} student={student} onError={handleErrors}/>
            </div>
        </>
    )
}

export default AttendanceGenerateReport