import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Spin, notification } from 'antd';
import './style.css';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_ROLE, DB_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import { useNavigate } from 'react-router-dom';

const AddRole = () => {
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);

  // Form State
  const [title, set_title] = useState('');
  const [description, set_description] = useState('');
  const [permissions, set_permissions] = useState([]);
  const [db_loader, set_db_loader] = useState(true);
  const [select_all, set_select_all] = useState(false);
  const [errors, set_errors] = useState([]);

  // DB list
  const DB_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const DB_LIST_API_RESPONSE = await DB_LIST(FORM_DATA);
    if (DB_LIST_API_RESPONSE?.data?.status) {
      set_permissions(DB_LIST_API_RESPONSE?.data?.tables);
      set_db_loader(false);
    } else {
      set_errors(DB_LIST_API_RESPONSE?.data?.errors);
      set_db_loader(false);
    }
  };

  useEffect(() => {
    DB_LIST_API();
  }, []);

  const update_permissions = (e, name, type) => {
    set_select_all(false);
    const new_permissions = permissions.map((perm) => {
      if (perm.name === name) {
        return { ...perm, [type]: e.target.checked };
      }
      return perm;
    });
    set_permissions(new_permissions);
  };

  const update_all_permissions = (e) => {
    const new_permissions = permissions.map((item) => ({
      ...item,
      view: e.target.checked,
      create: e.target.checked,
      update: e.target.checked,
      status: e.target.checked,
      delete: e.target.checked,
    }));
    set_select_all(e.target.checked);
    set_permissions(new_permissions);
  };

  const ADD_ROLE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('title', title);
    FORM_DATA.append('description', description);
    FORM_DATA.append('all_permissions', select_all ? 1 : 0);
    FORM_DATA.append('permissions', JSON.stringify(permissions));
    const ADD_ROLE_API_RESPONSE = await ADD_ROLE(FORM_DATA);
    if (ADD_ROLE_API_RESPONSE.data.status) {
      notification.open({
        message: 'Success!!',
        description: 'Role Successfully added.',
        icon: <SmileOutlined style={{ color: 'green' }} />,
      });
      navigate('/role-list');
    } else {
      set_errors(ADD_ROLE_API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <div>
      <div className='theme-content-head'>
        <div className='theme-content-left-head'>
          <h3>Add Role</h3>
        </div>
        <div className='theme-content-right-head'></div>
      </div>
      <div className='common-form'>
        {errors?.try && <span style={{ color: 'red' }}>{errors?.try[0]}</span>}
        {errors?.catch && <span style={{ color: 'red' }}>{errors?.catch[0]}</span>}
        <div className='row'>
          <div className='col-12'>
            <div className='input-box'>
              <label htmlFor='title'>
                Role Title<i style={{ color: 'red' }}>*</i>
              </label>
              <Input
                placeholder='Role Title'
                id='title'
                onChange={(e) => set_title(e.target.value)}
              />
              {errors?.title && (
                <span style={{ color: 'red' }}>{errors?.title[0]}</span>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='input-box'>
              <label htmlFor='title'>
                Role Description<i style={{ color: 'red' }}>*</i>
              </label>
              <Input.TextArea
               rows={6}
               autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder='Role Title'
                id='description'
                onChange={(e) => set_description(e.target.value)}
              />
              {errors?.description && (
                <span style={{ color: 'red' }}>{errors?.description[0]}</span>
              )}
            </div>
          </div>
          <div className='col-12'>
            <label htmlFor='title'>Access level/Permissions</label>
            <br />
            <br />
            <Checkbox checked={select_all} onChange={update_all_permissions}>
              Select All
            </Checkbox>
            {db_loader ? (
              <SisLoader />
            ) : (
              <div className='row' style={{ marginTop: '20px' }}>
                {permissions?.length > 0 ? (
                
                  permissions?.map((item, index) => (
                    <div className='col-3' key={index}>
                      <h4
                        style={{
                          marginBottom: '10px',
                          marginTop: '10px',
                          textTransform: 'uppercase',
                        }}
                      >
                        {item?.name === 'academic_calendars' && 'Academic Calendar'}
                        {item?.name === 'academic_semesters' && 'Semesters'}
                        {item?.name === 'add_course_payment' && 'Course Payment'}
                        {item?.name === 'address' && 'Address'}
                        {item?.name === 'attendance_categories' && 'Attendance Category'}
                        {item?.name === 'attendance_category_reasons' && 'Reasons'}
                        {item?.name === 'attendances' && 'Attendance'}
                        {item?.name === 'billings' && 'Semester Billing'}
                        {item?.name === 'calendars_schedules' && 'Schedule Calendar'}
                        {item?.name === 'categories' && 'Courses Categories'}
                        {item?.name === 'cgpa_manage_course_request' && 'CGPA Manage Course Request'}
                        {item?.name === 'class_rooms' && 'Rooms'}
                        {item?.name === 'courses' && 'Courses'}
                        {item?.name === 'enrollment_finances' && 'Enrollment Finances'}
                        {item?.name === 'enrollments' && 'Enrollment & Students'}
                        {item?.name === 'faculties' && 'Faculties'}
                        {item?.name === 'grade_plan' && 'Grade Plan'}
                        {item?.name === 'payment_plans' && 'Payment Plan'}
                        {item?.name === 'repeat_courses' && 'Repeat Courses'}
                        {item?.name === 'resgistration_payment' && 'Resgistration Payment'}
                        {item?.name === 'semester_registrations' && 'Semester Registration'}
                        {item?.name === 'sis_programmes' && 'Programs'}
                        {item?.name === 'student_final_bill' && 'Publish Bill'}
                        {item?.name === 'student_semester_health_insurance' && 'Student Services'}
                        {item?.name === 'student_signature' && 'Student Signature'}
                        {item?.name === 'students_grades' && 'Grades & Grades Management'}
                        {item?.name === 'students_programmes_registrations' && 'Program Registration'}
                        {item?.name === 'students_request' && 'students Request'}
                        {item?.name === 'users' && 'Users'}
                      </h4>
                      <Checkbox
                        checked={item.view || select_all}
                        onChange={(e) => update_permissions(e, item.name, 'view')}
                      >
                        View
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={item.create || select_all}
                        onChange={(e) => update_permissions(e, item.name, 'create')}
                      >
                        Create
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={item.update || select_all}
                        onChange={(e) => update_permissions(e, item.name, 'update')}
                      >
                        Update
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={item.status || select_all}
                        onChange={(e) => update_permissions(e, item.name, 'status')}
                      >
                        Status
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={item.delete || select_all}
                        onChange={(e) => update_permissions(e, item.name, 'delete')}
                      >
                        Delete
                      </Checkbox>
                    </div>
                  ))
                ) : (
                  <div className='col-12'>
                    <p>Data Empty</p>
                  </div>
                )}
              </div>
            )}
            <div className='input-box' style={{ marginTop: '15px' }}>
              {loader ? (
                <Button type='primary'  className='lusisbtn'>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: '12px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                    }
                  />{' '}
                  Save
                </Button>
              ) : (
                <Button type='primary' className='lusisbtn' onClick={ADD_ROLE_API}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
