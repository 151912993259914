import { useEffect, useState } from "react";
import { Button, notification, Spin } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { SIS_STORAGE, STUDENT_REPEAT_REQUEST_DETAIL, STUDENT_REQUEST_DETAIL_SUBMIT } from "../../../apis/apis";
import TextArea from "antd/es/input/TextArea";
import SisLoader from "../../../widgets/loader";

const StudentRepeatCourseRequestDetail = () => {
  const [textareainputvalue, settextareainputvalue] = useState("");
  const [course_info, set_course_info] = useState("");
  const [program, set_program] = useState("");
  const [semester, set_semester] = useState("");
  const [student_details, set_student_details] = useState("");
  const [student_request, set_student_request] = useState("");
  const [student_grade, set_student_grade] = useState("");
  const [loader, set_loader] = useState(false);

  const {std_id,c_id,sem_id} = useParams();

  const navigate = useNavigate();

  useEffect(()=>{
    VIEW_STUDENT_REPEAT_REQUEST_DETAIL();
  },[]);
   const VIEW_STUDENT_REPEAT_REQUEST_DETAIL = async() => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id",atob(std_id));
    FORM_DATA.append("course_id",atob(c_id));
    FORM_DATA.append("semester_id",atob(sem_id));
    const VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE = await STUDENT_REPEAT_REQUEST_DETAIL(FORM_DATA);
    if(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.status)
    {
      set_student_details(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.student_details);
      set_course_info(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.course_info);
      set_program(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.program);
      set_semester(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.semester
      );
      set_student_grade(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.student_grade);
      set_student_request(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.student_request);
      settextareainputvalue(VIEW_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.student_request_detail?.student_request?.admin_comment);
      set_loader(false);
    }
   }

   const ADD_STUDENT_REQUEST_DETAIL = async(approve_status) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id",atob(std_id));
    FORM_DATA.append("course_id",atob(c_id));
    FORM_DATA.append("semester_id",atob(sem_id));
    FORM_DATA.append("admin_comment",textareainputvalue);
    FORM_DATA.append("approve_status",approve_status);
    FORM_DATA.append("request_type",student_request?.request_type);
    const ADD_STUDENT_REQUEST_DETAIL_API_RESPONSE = await STUDENT_REQUEST_DETAIL_SUBMIT(FORM_DATA);
    if(ADD_STUDENT_REQUEST_DETAIL_API_RESPONSE?.data?.status)
    { 
      if( approve_status === 1)
      {

        notification.open({
          message: "Success!!",
          description: "Student Request Approved Successfully.",
          icon: <SmileOutlined style={{ color: "green" }} />,
        });
        navigate("/students-grade-detail/" + std_id)
      }
      set_loader(false);
    }
    else
    {
      set_loader(false)
      
   }

  }
  return (
    <>
    {loader ? <SisLoader/> : 
    <>
    <div className="row">
      <div class="col-3">
        <div style={{ display: "flex"}}>
        <div className="input-box" style={{ position: "relative" }}>
          { student_details?.image && 
          <img
            src={ SIS_STORAGE + "/enrollments/" + student_details?.image}
            alt="Profile"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "10px",
              marginRight: "15px",
            }}
          ></img>
          }
            </div>
             <div className="input-box" style={{ position: "relative", width:"200px"}}>
             <label style={{fontSize:"20px",fontWeight:"500"}}>
            {student_details?.first_name}{" "}{student_details?.middle_name != "null" && student_details?.middle_name}{" "}{student_details?.last_name}
          </label>
             </div>
        </div>
      </div>
      <div className="col-2">
        <div className="input-box" style={{ position: "relative" }}>
          <p>Application Number</p>
          <h4 style={{ marginTop: "6px" }}>
          {student_details?.application_no}
          </h4>
        </div>
      </div>
      <div className="col-2">
        <div className="input-box" style={{ position: "relative" }}>
          <p>Student ID</p>
          <h4 style={{ marginTop: "6px" }}>
          {student_details?.student_id}
          </h4>
        </div>
      </div>
      <div className="col-3">
        <div className="input-box" style={{ position: "relative" }}>
          <p>Programme/Degree</p>
         <h4  style={{ marginTop: "6px" }}>
          {program?.title}
          </h4>
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div className="row" style={{ margin: 0 }}>
      <h3>Request Details</h3>
    </div>
    <br />
    <div className="row">
      <div className="col-3">
        <p>Requested Course</p>
        <h4 style={{ marginTop: "6px" }}>{course_info?.code}  {course_info?.title}</h4>
      </div>
      <div className="col-2">
        <p>Original Grade</p>
        <h4  style={{ marginTop: "6px" }}>{student_grade?.grade}</h4>
      </div>
      <div className="col-2">
        <p>Semester Taken</p>
        <h4  style={{ marginTop: "6px" }}>{semester?.title}</h4>
      </div>
      <div className="col-3">
        <div className="input-box" style={{ margin: 0 }}>
          <Button
            onClick={(e) => navigate("/students-grade-detail/" + std_id)}
            type="primary"
          >
            Check Academic Details
          </Button>
        </div>
      </div>
    </div>
    <br />
    <div className="row" style={{ margin: 0 }}>
      <h3>Request Reason</h3>
    </div>
    <br />
    <div className="row" style={{ margin: 0 }}>
      <div
        className="col-6"
        style={{ border: "1px solid #cacaca", borderRadius: "5px" }}
      >
        <div className="input-box">
         {student_request?.comment}
        </div>
      </div>
    </div>
    <br />
    <div className="row" style={{ margin: 0 }}>
      <h3>Internal comments/notes</h3>
    </div>
    <div className="row">
      <div className="col-8">
        <div className="input-box">
          <label style={{ textTransform: "none", fontSize: "14px" }}>
            Comment/notes
          </label>
          <TextArea
           disabled={student_request?.approve_status === 1 ? true : false}
          value={textareainputvalue}
            onChange={(e) => settextareainputvalue(e.target.value)}
            rows={6}
            autoSize={{ minRows: 6, maxRows: 6 }}
            placeholder="..."
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-8">
        <div className="input-box" style={{ margin: 0, float: "right" }}>
        {loader ? (
          <>
            <Button type="primary">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  />
                }
              />{" "}
              Saving
            </Button>
          </>
        ) : (
          <>
            <Button
             disabled={student_request?.approve_status === 1 ? true : false}
             onClick={() =>  ADD_STUDENT_REQUEST_DETAIL(student_request?.approve_status)} type="primary">
          Save
            </Button>
          </>
        )}
        </div>
      </div>
    </div>
    <br />
    <div className="row" style={{ display: "flex", margin: 0, gap: "20px" }}>
      <div className="input-box" style={{ width: "100px" }}>
        {loader ? (
          <>
            <Button type="primary">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  />
                }
              />{" "}
              Approve
            </Button>
          </>
        ) : (
          <>
            <Button
            disabled={student_request?.approve_status === 1 ? true : false}
            onClick={() =>  ADD_STUDENT_REQUEST_DETAIL(1)} type="primary">
              <CheckOutlined /> Approve
            </Button>
          </>
        )}
      </div>
      <div className="input-box" style={{ width: "100px" }}>
        {loader ? (
          <>
            <Button type="primary" className="reject">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  />
                }
              />{" "}
              Reject
            </Button>
          </>
        ) : (
          <>
            <Button
             disabled={student_request?.approve_status === 1 ? true : false}
             onClick={() => ADD_STUDENT_REQUEST_DETAIL(2)} type="primary" className="reject">
              <CloseOutlined /> Reject
            </Button>
          </>
        )}
      </div>
    </div>
  </>
    }
    </>
  );
};

export default StudentRepeatCourseRequestDetail;
