import React, { useEffect, useState } from "react";
import SisLoader from "../../../../widgets/loader";
import { useNavigate } from "react-router-dom";
import { ADVANCE_ACADEMIC_PERFORMANCE_REPORT, ADVANCE_STUDENT_DEMOGRAPHICS_REPORT, CALENDARS_LIST, PROGRAMS_LIST } from "../../../../apis/apis";
import { Button, Input, Select, Slider } from "antd";
import { CountryDropdown } from "react-country-region-selector";
import AdvancedStdDemoReportSummaryTable from "./advancedStdDemoReportSummaryTable";

const AdvancedStdDemoReportVisualSection = () => {
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [student_category, set_student_category] = useState("");
  const [admission_status, set_admission_status] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [student_type, set_student_type] = useState("");
  const [academic_year, set_academic_year] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [gender, set_gender] = useState("");
  const [identify, set_identify] = useState("");
  const [age, set_age] = useState("");
  const [citizenship, set_citizenship] = useState("");
  const [home_country_address_state, set_home_country_address_state] =
    useState("");
  const [home_country_address_country, set_home_country_address_country] =
    useState("");
    const [student_athlete, set_student_athlete] = useState("");
  const [academic_year_list, set_academic_year_list] = useState([]);
  const [semester, set_semester] = useState("");
  const [sem_list, set_sem_list] = useState([]);
  const [programme_list, set_programme_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);
  const navigate = useNavigate();

  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_academic_year_list(API_RESPONSE?.data?.calendars);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  // const calender_change_function = (value) => {
  //   set_semester("");
  //   set_errors("");
  //   const index = academic_year_list.findIndex((item) => item.id === value);
  //   set_academic_year(value);
  //   set_calender_index(index);
  //   set_sem_list(academic_year_list[index].semesters);
  // };

  const calender_change_function = (value) => {
    set_semester("");
    set_errors("");
    const index = academic_year_list.findIndex((item) => item.id === value);
  
    if (index !== -1) { // Only proceed if a valid academic year is selected
      set_academic_year(value);
      set_calender_index(index);
      set_sem_list(academic_year_list[index]?.semesters || []);  // Ensure semesters exist or use an empty array
    } else {
      // Handle case when custom option (e.g., "Please select academic calendar") is selected
      set_academic_year("");  // Reset academic year
      set_calender_index(-1);  // Reset index
      set_sem_list([]);  // Reset semesters list
    }
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };


  const GENERATE_ADVANCE_STUDENT_DEMOGRAPHICS_REPORT = async()=>{
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", academic_year);
    FORM_DATA.append("semester_id", semester);
    FORM_DATA.append("entering_classification", entering_classification);
    FORM_DATA.append("program_id", programme_intended);
    FORM_DATA.append("student_category", student_category);
    FORM_DATA.append("student_athlete", student_athlete);
    FORM_DATA.append("admission_status", admission_status);
    FORM_DATA.append("student_type", student_type);
    FORM_DATA.append("home_country_address_state", home_country_address_state);
    FORM_DATA.append("home_country_address_country", home_country_address_country);
    FORM_DATA.append("citizenship", citizenship);
    FORM_DATA.append("age", age);
    FORM_DATA.append("gender", gender);
    FORM_DATA.append("identify", identify);
    const API_RESPONSE = await ADVANCE_STUDENT_DEMOGRAPHICS_REPORT(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_table_list(API_RESPONSE?.data?.report);
      set_loader(false);
    }
    else{
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  }


  useEffect(() => {
    LIST_API();
    PROGRAMS_LIST_API();
  }, [academic_year]);

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="theme-content-left-head">
            <h2>Student Demographics Reports</h2>
            <br />
            <p>
              Provide detailed demographic information for institutional
              research and decision-making.
            </p>
          </div>
          <br />
          <hr />
          <br />
          <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#4b4b4b" }}>
            Filters
          </h3>
          <br />
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Academic Year<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                  placeholder="--Select Academic Year--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={calender_change_function}
                  value={
                    academic_year ? parseInt(academic_year) : academic_year
                  }
                  options={[
                    { value: "", label: "Please select academic calendar" }, // Custom option
                    ...academic_year_list.map((item) => ({
                      value: item.id,
                      calender_id: item.id,
                      label: item.title,
                    })),
                  ]}
                />
                {errors?.acad_calender_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Academic Year field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Semester<i style={{ color: "red" }}>*</i>
                </label>
                <br />

                <Select
                  placeholder="--Select Semester--"
                  style={{ width: "100%", height: "40px" }}
                  disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                  onChange={(value) => {
                    set_semester(value);
                  }}
                  value={semester}
                  options={[
                    { value: "", label: "Please select semester" }, // Static custom option
                    ...sem_list.map((item) => ({
                      value: item?.id,
                      label: item?.title,
                    })),
                  ]}
                />

                {errors?.semester_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The semester field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Student Level<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                  onChange={(value) => {
                    set_entering_classification(value);
                  }}
                  value={entering_classification}
                  style={{ width: "100%", height: "40px" }}
                  placeholder="Select Entering Classifications"
                  required
                >
                   <Select.Option value="">
                    Please select student level
                  </Select.Option>
                  <Select.Option value="undergraduate">
                    Undergraduate
                  </Select.Option>
                  <Select.Option value="graduate">Graduate</Select.Option>
                  <Select.Option value="doctoral">Doctoral</Select.Option>
                </Select>
                {errors?.entering_classification && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.entering_classification[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Program / Major<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                  value={programme_intended}
                  placeholder="--Select programme--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_programme_intended(value)}
                >
                   <Select.Option value="">
                   Please select program
                  </Select.Option>
                  {programme_list.map((item) => {
                    if (item.status === 1 && item.soft_delete === 0) {
                      return (
                        <>
                          <Select.Option value={item.id}>
                            {item.title}
                          </Select.Option>
                        </>
                      );
                    }
                  })}
                </Select>
                {errors?.program_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Program field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <h3 style={{ fontSize: "15px", fontWeight: "600", color: "#4b4b4b" }}>
            Enrollment & Admission Status
          </h3>
          <div className="row">
          <div className="col-3">
              <div className="input-box">
                <label>Student Category</label>
                <br />
                <Select
                  value={student_category}
                  style={{ width: "100%", height: "40px" }}
                  placeholder="--Select Student Category--"
                  onChange={(value) => set_student_category(value)}
                >
                   <Select.Option value="">
                   Please select student category
                  </Select.Option>
                  <Select.Option value="International">
                    International
                  </Select.Option>
                  <Select.Option value="Domestic">Domestic</Select.Option>
                </Select>
                {errors?.student_category && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_category[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
                <div className="input-box">
                  <label>
                  Student Athlete
                  </label>
                  <br></br>
                  <Select
                  value={student_athlete}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(value) => set_student_athlete(value)}
                    placeholder="--Select Student Athlete--"
                  >
                     <Select.Option value="">
                   Please select student athlete
                  </Select.Option>
                    <Select.Option value={1}>Yes</Select.Option>
                    <Select.Option value={0}>No</Select.Option>
                  </Select>
                  {errors?.student_athlete && (
                    <>
                      <span style={{ color: "red" }}>{errors?.student_athlete[0]}</span>
                    </>
                  )}
                </div>
              </div>
            <div className="col-3">
              <div className="input-box">
                <label>Admission Status</label>
                <Select
                  value={admission_status === "null" ? "" : admission_status}
                  onChange={(value) => set_admission_status(value)}
                  style={{ width: "100%", height: "40px" }}
                  placeholder="--Admission Status--"
                >
                   <Select.Option value="">
                   Please select admission status
                  </Select.Option>
                  <Select.Option value="Full Time">Full Time</Select.Option>
                  <Select.Option value="Part Time">Part Time</Select.Option>
                </Select>
                {errors?.admission_status && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.admission_status[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Student Type</label>
                <br></br>
                <Select
                  style={{ width: "100%", height: "40px" }}
                  placeholder="-- Student Type--"
                  onChange={(value) => {
                    set_student_type(value);
                  }}
                  value={student_type}
                >
                   <Select.Option value="">
                   Please select student type
                  </Select.Option>
                  {["New", "Transferred", "Returning", "Alumni"].map((item) => {
                    return (
                      <>
                        <Select.Option value={item}>{item}</Select.Option>
                      </>
                    );
                  })}
                </Select>
                {errors?.student_type && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_type[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <h3 style={{ fontSize: "15px", fontWeight: "600", color: "#4b4b4b" }}>
            Geographic Data
          </h3>
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label> Home State</label>
                <Input
                  placeholder="State"
                  value={home_country_address_state}
                  onChange={(e) =>
                    set_home_country_address_state(e.target.value)
                  }
                />
                {errors?.home_country_address_state && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.home_country_address_state[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label> Home Country</label>
                <CountryDropdown
                  style={{ width: "100%", height: "40px" }}
                  value={home_country_address_country}
                  key={"home_country_address_country"}
                  onChange={(value) => set_home_country_address_country(value)}
                  className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined"
                />
                {errors?.home_country_address_country && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.home_country_address_country[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Citizenship</label>
                <CountryDropdown
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_citizenship(value)}
                  value={citizenship}
                  className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                />
                {errors?.citizenship && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.citizenship[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
           
          </div>
          <br />
          <h3 style={{ fontSize: "15px", fontWeight: "600", color: "#4b4b4b" }}>
            Demographic Category
          </h3>
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>Age</label>
                <br />
                <Slider
                  min={15}
                  max={40}
                  onChange={(value) => {
                    if (Number.isNaN(value)) {
                      return;
                    }
                    set_age(value);
                  }}
                  value={typeof age === "number" ? age : 0}
                  step={1}
                />
                {errors?.age && (
                  <>
                    <span style={{ color: "red" }}>{errors?.age[0]}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Gender</label>
                <br></br>
                <Select
                  value={gender}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_gender(value)}
                  placeholder="--Select Gender--"
                >
                  <Select.Option value="">Please select gender</Select.Option>
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="others">Others</Select.Option>
                </Select>
                {errors?.gender && (
                  <>
                    <span style={{ color: "red" }}>{errors?.gender[0]}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Ethnicity</label>
                <Select
                  disabled={localStorage.getItem("payment_status") == 1}
                  value={identify}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_identify(value)}
                  placeholder="--identify yourself--"
                >
                   <Select.Option value="">Please select ethnicity</Select.Option>
                  <Select.Option value="american indian or native alaskan">
                    American Indian or Native Alaskan
                  </Select.Option>
                  <Select.Option value="asian or pacific islander">
                    Asian or Pacific Islander
                  </Select.Option>
                  <Select.Option value="african american">
                    African American
                  </Select.Option>
                  <Select.Option value="hispanic">Hispanic</Select.Option>
                  <Select.Option value="white">White</Select.Option>
                </Select>
                {errors?.identify && (
                  <>
                    <span style={{ color: "red" }}>{errors?.identify[0]}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <Button
               onClick={GENERATE_ADVANCE_STUDENT_DEMOGRAPHICS_REPORT
               }
            style={{
              backgroundColor: "#4b4b4b",
              color: "#ffffff",
              width: "156px",
              height: "40px",
            }}
            type="primary"
          >
            Generate Report
          </Button>
          <br />
          <br />
          {table_list?.length > 0 && (
          <AdvancedStdDemoReportSummaryTable
           table_list={table_list} 
           academic_year={academic_year}
           semester={semester}
           entering_classification={entering_classification}
           programme_intended={programme_intended}
           student_category={student_category}
           student_athlete={student_athlete}
           admission_status={admission_status}
           student_type={student_type}
           home_country_address_state={home_country_address_state}
           home_country_address_country={home_country_address_country}
           citizenship={citizenship}
           age={age}
           gender={gender}
           identify={identify}
           />
          )}
        </>
      )}
    </>
  );
};

export default AdvancedStdDemoReportVisualSection;
