import { Checkbox, Input, notification } from 'antd';
import Search from 'antd/es/transfer/search';
import React, { useEffect, useState } from 'react';
import { COURSES_LIST, SEARCH_COURSE, SEMESTERS_COURSES_UPDATE, SEMESTERS_COURSES_DELETE } from '../../../apis/apis';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import SearchCourseCheckbox from './SearchCourseCheckbox'
import SisLoader from '../../../widgets/loader';
const SearchCourse = (props) => {
    const { calendar_id, select_courses, sem_id} = props;
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [table_list, set_table_list] = useState([]);
    const [table_list_search, set_table_list_search] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if(localStorgeData.role === 2074){
            return true;
        }
        var check_permissions = [];
        if (type === 'view') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
      }
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }
    
        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    
    const LIST_API = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('calendar_id', calendar_id);
        FORM_DATA.append('semester_id', sem_id);
        const API_RESPONSE = await SEARCH_COURSE(FORM_DATA);
        if (API_RESPONSE.data.status) {
            set_table_list(API_RESPONSE?.data?.courses);
            set_table_list_search(API_RESPONSE?.data?.courses);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        LIST_API();
    }, []);

    const UPDATE_API = async (course_id, id) => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('calendar_id',  atob(calendar_id));
        FORM_DATA.append('semester_id', atob(sem_id));
        FORM_DATA.append('course_id', course_id);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Course section added successfully!',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/semester-courses/' + calendar_id + '/' + sem_id)
            set_loader(false)
        } else {
            set_errors(API_RESPONSE.data.errors);
        }
    }
    const DELETE_API = async (schedule_id) => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
    
        FORM_DATA.append('id', schedule_id);
        const API_RESPONSE = await SEMESTERS_COURSES_DELETE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Course section removed successfully!',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_loader(false)
           
            navigate('/semester-courses/' + calendar_id + '/' + sem_id)
        } else {
            set_errors(API_RESPONSE.data.errors);
        }
    }
    const set_sem_course = (e,item, selected_course_row) => {
        if(e.target.checked){
            UPDATE_API(item.id, 0)
          
        }else{
            var filter = selected_course_row.filter(fitem => (fitem.course_id === item.id));
            DELETE_API(filter[0].schedule_id)
          
        }
    }
    

// Use the filteredData to render your cards
    const onSearch = (value) => {
        var table_data = table_list;
        if(value.length > 0){
            const filteredData = table_list.filter((item) =>
            item.title.toLowerCase().includes(value.toLowerCase()) || item.code.toLowerCase().includes(value.toLowerCase())
            );
            set_table_list_search(filteredData);
        }else{
            set_table_list_search(table_list);
        }
       
    }
    return (
        <>
        {loader ? <SisLoader/>
        :
        <>

            <h3>Search Courses</h3>
                        <Input
            icon='search'
            placeholder='Search...'
            onChange={(e) => onSearch(e.target.value)}
            addonAfter={<SearchOutlined />}
            />
            {/* <Search
                placeholder="input search text"
                allowClear
                enterButton="Search"
                size="large"
              onSearch={onSearch}
            /> */}
            <ul className='course-list-with-checkbox'>
                {table_list_search?.length > 0 ? <>
                    {table_list_search?.map((item, index) => (
                        <>
                            <li>
                                <div>
                                   
                                    <SearchCourseCheckbox user_count={item.user_count}  calendar_id={calendar_id} select_courses={select_courses} item={item} sem_id={sem_id} />
                                </div>
                                <div>
                                <b  style={{ backgroundColor:"#74b4f0", color:"#000" }}><UserOutlined /> {item.user_count}</b>
                                    <b>{item.code}</b>
                                    {/* <b>{item.user_count}</b> */}
                                </div>
                            </li>
                        </>
                    ))}

                </> : <>
                    <li>Please add Courses</li>
                </>}
            </ul>

        </>
}
        </>
    );
};

export default SearchCourse;