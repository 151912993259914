import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import { TableColumnsType, TableProps } from "antd";
import {
  CloseOutlined,
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";

import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import dayjs from "dayjs";
import {
  ADD_STUDENT,
  ADD_USER,
  PROGRAMS_LIST,
  STUDENTS_API,
  ROLE_LIST,
  SIS_STORAGE,
  NEW_ENROLLMENT,
  UPDATE_ENROLLMENT,
  ENROLLMENTS_UPDATE,
  VIEW_ENROLLMENT,
  ENROLLMENTS_UPDATE_POPUP,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";

const EditStudentPopup = (props) => {
  const { id } = useParams();
  const dateFormat = "MM-DD-YYYY";
  const DATE_CLASS = new Date();
  const current_month =
    DATE_CLASS.getMonth() < 9
      ? "0" + (DATE_CLASS.getMonth() + 1)
      : DATE_CLASS.getMonth();
  const today_date =
    DATE_CLASS.getFullYear() + "-" + current_month + "-" + DATE_CLASS.getDate();
  const [page_loader, set_page_loader] = useState(true);

  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [role_list, set_role_list] = useState([]);
  const studentImage = useRef(null);

  // Form State
  const [application_start_on, set_application_start_on] = useState(today_date);
  const [application_no, set_application_no] = useState("");

  // basic information
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");

  const [student_id, set_student_id] = useState("");
  const [gender, set_gender] = useState("");
  const [birth_date, set_birth_date] = useState("");
  const [country_of_birth, set_country_of_birth] = useState("");
  const [city_of_birth, set_city_of_birth] = useState("");
  const [student_category, set_student_category] = useState("");
  const [ssn, set_ssn] = useState("");
  const [ssnerror, set_Ssn_Error] = useState('');
  const [programme_list, set_programme_list] = useState([]);
  const [student_status_check, set_student_status_check] = useState();
  // Contact
  const [phoneerror, set_Phone_Error] = useState('');
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [secondary_email, set_secondary_email] = useState("");
  const [citizenship, set_citizenship] = useState("");
  const [mailing_address1, set_mailing_address1] = useState("");
  const [mailing_address2, set_mailing_address2] = useState("");
  const [mailing_address_city, set_mailing_address_city] = useState("");
  const [mailing_address_state, set_mailing_address_state] = useState("");
  const [mailing_address_postal, set_mailing_address_postal] = useState("");
  const [mailing_address_country, set_mailing_address_country] = useState("");
  const [home_country_address1, set_home_country_address1] = useState("");
  const [home_country_address2, set_home_country_address2] = useState("");
  const [home_country_address_city, set_home_country_address_city] =
    useState("");
  const [home_country_address_state, set_home_country_address_state] =
    useState("");
  const [home_country_address_postal, set_home_country_address_postal] =
    useState("");
  const [home_country_address_country, set_home_country_address_country] =
    useState("");

  const [social_security_number, set_social_security_number] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [school_decision, set_school_decision] = useState("");
  const [application_checklist, set_application_checklist] = useState("");
  const [financial_aid, set_financial_aid] = useState("");

  // Document
  const [enrollments_agreements, set_enrollments_agreements] = useState("");
  const [
    high_school_diploma_and_transcripts,
    set_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    transcripts_from_higher_educational,
    set_transcripts_from_higher_educational,
  ] = useState("");
  const [resume, set_resume] = useState("");
  const [
    photocopy_of_passport_or_equivalent_documents,
    set_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [proof_of_english, set_proof_of_english] = useState("");
  const [essay_explaining_interest, set_essay_explaining_interest] =
    useState("");

  const [api_image, set_api_image] = useState("");
  const [api_enrollments_agreements, set_api_enrollments_agreements] =
    useState("");
  const [
    api_high_school_diploma_and_transcripts,
    set_api_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    api_transcripts_from_higher_educational,
    set_api_transcripts_from_higher_educational,
  ] = useState("");
  const [api_resume, set_api_resume] = useState("");
  const [api_proof_of_english, set_api_proof_of_english] = useState("");
  const [
    api_photocopy_of_passport_or_equivalent_documents,
    set_api_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [api_essay_explaining_interest, set_api_essay_explaining_interest] =
    useState("");

  // Registration Status
  const [student_status, set_student_status] = useState("");

  // Alert state

  const [showStudentImageAlert, setShowStudentImageAlert] = useState(false);

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await VIEW_ENROLLMENT(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_application_no(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_no
      );
      set_first_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.first_name);
      set_middle_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.middle_name);
      set_last_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.last_name);
      set_student_id(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_id);
      set_birth_date(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.birth_date);
      set_country_of_birth(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.country_of_birth
      );
      set_city_of_birth(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.city_of_birth
      );
      set_gender(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.gender);
      set_api_image(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.image);
      set_citizenship(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.citizenship);
      set_ssn(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.ssn);
      set_student_category(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_category
      );
      set_email(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.primary_email);
      set_secondary_email(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.secondary_email
          ? EDIT_ROLE_API_RESPONSE?.data?.enrollments?.secondary_email
          : ""
      );
      set_phone(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.phone);
      var mailing_address = JSON.parse(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.mailing_address
      );
      set_mailing_address1(mailing_address.mailing_address1);
      set_mailing_address2(mailing_address.mailing_address2);
      set_mailing_address_city(mailing_address.mailing_address_city);
      set_mailing_address_postal(mailing_address.mailing_address_postal);
      set_mailing_address_country(mailing_address.mailing_address_country);

      var home_country_address = JSON.parse(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.home_country_address
      );
      set_home_country_address1(home_country_address.home_country_address1);
      set_home_country_address2(home_country_address.home_country_address2);
      set_home_country_address_city(
        home_country_address.home_country_address_city
      );
      set_home_country_address_postal(
        home_country_address.home_country_address_postal
      );
      set_home_country_address_country(
        home_country_address.home_country_address_country
      );

      set_programme_intended(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.programme_intended
      );
      set_school_decision(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.school_decision
      );
      set_application_checklist(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_checklist
      );
      set_financial_aid(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.financial_aid
      );
      set_student_status(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_status
      );
      set_student_status_check(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_status
      );

      set_api_enrollments_agreements(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.enrollments_agreements
      );
      set_api_high_school_diploma_and_transcripts(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.high_school_diploma_and_transcripts
      );
      set_api_transcripts_from_higher_educational(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.transcripts_from_higher_educational
      );
      set_api_resume(EDIT_ROLE_API_RESPONSE?.data?.documents?.resume);
      set_api_photocopy_of_passport_or_equivalent_documents(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.photocopy_of_passport_or_equivalent_documents
      );
      set_api_proof_of_english(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.proof_of_english
      );
      set_api_essay_explaining_interest(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.essay_explaining_interest
      );

      set_page_loader(false);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
    }
  };
  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    PROGRAMS_LIST_API();
    VIEW_API();
    // STUDENTS_API();
  }, []);

  const handleImageChange = (e, type) => {
    const files = e.target.files;
    if (type === "image") {
      if (files && files.length > 0) {
        const file = files[0];
        const maxSize = 2 * 1024 * 1024;

        if (file.size < maxSize) {
          setShowStudentImageAlert(false);
          set_image(file);
        } else {
          set_image("");
          setShowStudentImageAlert(true);
          studentImage.current.value = "";
        }
    }
    }
  };

  // handleRemove Image
  const handleRemoveImage = (e, type) => {
    if (type == "enrollments_agreements") {
      set_enrollments_agreements("");
    }
    if (type === "high_school_diploma_and_transcripts") {
      set_high_school_diploma_and_transcripts("");
    }
    if (type === "resume") {
      set_resume("");
    }
    if (type === "image") {
      set_image("");
      studentImage.current.value='';
    }
    if (type === "photocopy_of_passport_or_equivalent_documents") {
      set_photocopy_of_passport_or_equivalent_documents("");
    }
    if (type === "transcripts_from_higher_educational") {
      set_transcripts_from_higher_educational("");
    }
    if (type === "proof_of_english") {
      set_proof_of_english("");
    }
    if (type === "essay_explaining_interest") {
      set_essay_explaining_interest("");
    }
  };

  const UPDATE_API = async () => {
    if(!phoneerror && !ssnerror)
      {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("application_start_on", application_start_on);

    FORM_DATA.append("application_no", application_no);
    FORM_DATA.append("id", id);
    //Basic Information
    FORM_DATA.append("image", image);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("birth_date", birth_date);
    FORM_DATA.append("country_of_birth", country_of_birth);
    FORM_DATA.append("city_of_birth", city_of_birth);
    FORM_DATA.append("gender", gender);
    FORM_DATA.append("citizenship", citizenship);
    FORM_DATA.append("ssn", ssn);
    FORM_DATA.append("student_category", student_category);

    // Contacts
    FORM_DATA.append("email", email);
    FORM_DATA.append("secondary_email", secondary_email);
    FORM_DATA.append("phone", phone);

    // Mailing Address
    FORM_DATA.append("mailing_address_country", mailing_address_country);
    FORM_DATA.append("mailing_address_city", mailing_address_city);
    FORM_DATA.append("mailing_address_postal", mailing_address_postal);
    FORM_DATA.append("mailing_address1", mailing_address1);
    FORM_DATA.append("mailing_address2", mailing_address2);

    // Home Address
    FORM_DATA.append(
      "home_country_address_country",
      home_country_address_country
    );
    FORM_DATA.append("home_country_address_city", home_country_address_city);
    FORM_DATA.append(
      "home_country_address_postal",
      home_country_address_postal
    );
    FORM_DATA.append("home_country_address1", home_country_address1);
    FORM_DATA.append("home_country_address2", home_country_address2);

    FORM_DATA.append("programme_intended", programme_intended);
    FORM_DATA.append("school_decision", school_decision);
    FORM_DATA.append("application_checklist", application_checklist);
    FORM_DATA.append("financial_aid", financial_aid);

    const API_RESPONSE = await ENROLLMENTS_UPDATE_POPUP(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Basic Information Successfully Updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/student-detail/" + id);
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
    console.log(API_RESPONSE);
  }
  };

  return (
    <>
     <Modal style={{ minWidth:"1000px" }} title="Profile" open={true} footer={false} onCancel={(e) => navigate("/student-detail/" + id)}>
     {page_loader ? (
          <SisLoader />
        ) : (
          <>
            <div className="common-form" style={{ position:"relative", zIndex:"100000" }}>
              {errors?.try && (
                <>
                  <span style={{ color: "red" }}>{errors?.try[0]}</span>
                </>
              )}
              {errors?.catch && (
                <>
                  <span style={{ color: "red" }}>{errors?.catch[0]}</span>
                </>
              )}

              {/* Basic Information */}
              <>
                <>
                  <div className="row">
                    <div className="col-3">
                      <p>Application Number</p>
                      <h4>{application_no}</h4>
                    </div>
                    <div className="col-3">
                      <p>Student ID</p>
                      <h4>{student_id}</h4>
                    </div>
                  </div>
                  <div className="row">
                    {/* Student Image */}
                    <div className="col-4">
                      <div
                        className="input-box"
                        style={{ position: "relative" }}
                      >
                        <label>Image</label>
                        <br></br>
                        <label className="upload-box upload-box-2  upload-box-3">
                          <input
                          ref={studentImage}
                            type="file"
                            id="upload-images"
                            accept="image/*"
                            style={{ height: "0px", overflow: "hidden" }}
                            onChange={(e) => handleImageChange(e, "image")}
                          />
                          <CloudUploadOutlined />
                          Upload
                        </label>
                        {showStudentImageAlert ? (
                  <>
                    <Alert
                      message="Error"
                      description="Image file format must be .jpg, .jpeg or .png and the image size should not exceed 2MB."
                      type="error"
                      showIcon
                      closable
                      onClose={() => setShowStudentImageAlert(false)}
                    />
                    <br />
                  </>
                ) : (
                  <>
                        {image ? (
                          <>
                            <div className="image-box">
                              <img
                                src={URL.createObjectURL(image)}
                                frameborder="0"
                                style={{ width: "100%", height: "100%" }}
                              ></img>
                            </div>
                            <button
                              className="image-remove"
                              onClick={(e) => handleRemoveImage(e, "image")}
                            >
                              <CloseOutlined />
                            </button>
                          </>
                        ) : (
                          <>
                            {api_image && (
                              <>
                                <div className="image-box">
                                  <img
                                    src={
                                      SIS_STORAGE + "/enrollments/" + api_image
                                    }
                                    frameborder="0"
                                    style={{ width: "100%", height: "150px" }}
                                  ></img>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {errors?.image && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.image[0]}
                            </span>
                          </>
                        )}
                         </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* First Name */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>
                          First Name<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={first_name}
                          placeholder="First Name"
                          onChange={(e) => set_first_name(e.target.value)}
                        />
                        {errors?.first_name && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.first_name[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Middle Name */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>Middle Name (Optional)</label>
                        <Input
                          value={middle_name === "null" ? "" : middle_name}
                          placeholder="Middle Name"
                          onChange={(e) => set_middle_name(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>
                          Last Name<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={last_name}
                          placeholder="Last Name"
                          onChange={(e) => set_last_name(e.target.value)}
                        />
                        {errors?.last_name && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.last_name[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/* Student ID */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Student ID<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={student_id}
                          placeholder="Student ID"
                          onChange={(e) => set_student_id(e.target.value)}
                        />
                        {errors?.student_id && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.student_id[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Birth of Date */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Date Of Birth<i style={{ color: "red" }}>*</i>
                        </label>
                        <DatePicker format={dateFormat}
                          value={birth_date && dayjs(birth_date)}
                          onChange={(date, dateString) =>
                            set_birth_date(dateString)
                          }
                          style={{ width: "100%" }}
                        />
                        {errors?.birth_date && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.birth_date[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Country Of Birth */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Country Of Birth<i style={{ color: "red" }}>*</i>
                        </label>
                        <CountryDropdown
                          key={"country_of_birth"}
                          value={country_of_birth}
                          onChange={(value) => set_country_of_birth(value)}
                          className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                        />
                        {errors?.country_of_birth && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.country_of_birth[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Birth Place / City */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Birth Place<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          placeholder="Enter Your Birth City"
                          value={city_of_birth}
                          onChange={(e) => set_city_of_birth(e.target.value)}
                        />
                        {errors?.city_of_birth && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.city_of_birth[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Gender */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Gender<i style={{ color: "red" }}>*</i>
                        </label>
                        <br></br>
                        <Select
                          value={gender}
                          style={{ width: "100%", height:"40px" }}
                          onChange={(value) => set_gender(value)}
                          placeholder="--Select Gender--"
                        >
                          <Select.Option value="male">Male</Select.Option>
                          <Select.Option value="female">Female</Select.Option>
                          <Select.Option value="others">Others</Select.Option>
                        </Select>
                        {errors?.gender && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.gender[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Citizenship */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Citizenship<i style={{ color: "red" }}>*</i>
                        </label>
                        <CountryDropdown
                         style={{ width: "100%", height:"40px" }}
                          key={"citizenship"}
                          onChange={(value) => set_citizenship(value)}
                          value={citizenship}
                          className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                        />
                        {errors?.citizenship && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.citizenship[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* SSN(Optional) */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>SSN (Optional)</label>
                        <Input
                   value={ssn === "null" ? "" : ssn}
                  maxLength={11}
                    placeholder="SSN"
                   // onChange={(e) => set_ssn(e.target.value)}
                    keyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const ssnNumber = e.target.value;
                      // Remove non-digit characters from the input
                      const cleanedSsnNumber = ssnNumber.replace(/[^0-9]/g, '');
                      if (cleanedSsnNumber.length <= 9)
                        {
                          set_Ssn_Error('')
                           // Format the phone number as 510-62-8022
                      let formattedSsnNumber = '';
                      for (let i = 0; i < cleanedSsnNumber.length; i++) {
                        if (i === 3 || i === 5) {
                          formattedSsnNumber += '-';
                        }
                        formattedSsnNumber += cleanedSsnNumber[i];
                      }
                      set_ssn(formattedSsnNumber);
                          set_Ssn_Error('')
                        }
                        else
                        {
                          set_ssn(cleanedSsnNumber)
                          set_Ssn_Error("SSN Number Maximum Length Should be 9 Digit ")
                        }
                    }}
                  />
                   {errors?.ssn && (<><span style={{ color: "red" }}>{errors?.ssn[0]}</span></>)
                   || ssnerror && (<><span style={{ color: "red" }}>{ssnerror}</span></>)
                  }
                      </div>
                    </div>

                    {/* Student Category */}
                    <div className="col-3">
                      <div className="input-box">
                        <label>
                          Student Category<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />
                        <Select
                          value={student_category}
                          style={{ width: "100%", height:"40px" }}
                          placeholder="--Select Student Category--"
                          onChange={(value) => set_student_category(value)}
                        >
                          <Select.Option value="International">
                            International
                          </Select.Option>
                          <Select.Option value="Domestic">
                            Domestic
                          </Select.Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </>

                {/*Contact Details  */}
                <>
                  <br />
                  <div className="theme-content-left-head">
                    <h3>Contact Details </h3>
                  </div>
                  <br />
                  <div className="row">
                    {/* Prime Email */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="email">
                          Primary Email<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          id="email"
                          value={email}
                          placeholder="example123@gmail.com"
                          onChange={(e) => set_email(e.target.value)}
                          disabled
                        />
                        {errors?.email && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.email[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Secondary Email */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="secoundary_email">
                          Secondary Email
                        </label>
                        <Input
                          value={secondary_email ? secondary_email : ''}
                          id="secondary_email"
                          placeholder="secondaryemail123@gmail.com"
                          onChange={(e) => set_secondary_email(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Phone Number */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="phone">
                          Phone Number<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={phone}
                          maxLength={10}
                          placeholder="Phone Number"
                          disabled
                          keyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          id="phone"
                          //onChange={(e) => set_phone(e.target.value)}
                          onChange={(e) => {
                            const phoneNumber = e.target.value;
                            // Remove non-digit characters from the input
                            const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                            if (cleanedPhoneNumber.length <= 10)
                              {
                                set_Phone_Error('')
                                 // Format the phone number as 510-628-8022
                            let formattedPhoneNumber = '';
                            for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                              if (i === 3 || i === 6) {
                                formattedPhoneNumber += '-';
                              }
                              formattedPhoneNumber += cleanedPhoneNumber[i];
                            }
                                set_phone(formattedPhoneNumber);
                                set_Phone_Error('')
                              }
                              else
                              {
                                set_phone(cleanedPhoneNumber)
                                set_Phone_Error("Phone Number Maximum Length Should be 10 Digit ")
                              }
                          }}
                        />
                        {errors?.phone && (<><span style={{ color: "red" }}>{errors?.phone[0]}</span></>)
                         || phoneerror && (<><span style={{ color: "red" }}>{phoneerror}</span></>)
                        }
                      </div>
                    </div>
                  </div>

                  {/* Mailing Address */}
                  <label className="cus-label">
                    Mailing Address<i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="row">
                    {/* country */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="country">
                          Country<i style={{ color: "red" }}>*</i>
                        </label>
                        <CountryDropdown
                         style={{ width: "100%", height:"40px" }}
                          placeholde="--Select Country--"
                          key={"mailing_address_country"}
                          value={mailing_address_country}
                          onChange={(value) =>
                            set_mailing_address_country(value)
                          }
                          className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                        />
                        {errors?.mailing_address_country && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.mailing_address_country[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* city */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="email">
                          City<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={mailing_address_city}
                          placeholder="City"
                          onChange={(e) =>
                            set_mailing_address_city(e.target.value)
                          }
                        />
                        {errors?.mailing_address_city && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.mailing_address_city[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Postal Code */}
                    <div className="col-4">
                      <div className="input-box">
                        <label htmlFor="email">
                          Zip / Postal Code<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={mailing_address_postal}
                          placeholder="Zip/Postal"
                          onChange={(e) =>
                            set_mailing_address_postal(e.target.value)
                          }
                          keyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.mailing_address_postal && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.mailing_address_postal[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
              </div>
              <div className="row">
                {/* Mailing Address1 */}
                <div className="col-4">
                  <div className="input-box">
                    <label>
                    Address Field 1<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={mailing_address1}
                      placeholder="Address"
                      onChange={(e) => set_mailing_address1(e.target.value)}
                    />
                    {errors?.mailing_address1 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address1[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                 {/* Mailing Address2 */}
                 <div className="col-4">
                  <div className="input-box">
                    <label>
                    Address Field 2
                    </label>
                    <Input
                     value={mailing_address2 ? mailing_address2 : ''}
                      placeholder="Address"
                      onChange={(e) => set_mailing_address2(e.target.value)}
                    />
                    {errors?.mailing_address2 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address2[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

                  {/* Home Address */}
                  <label className="cus-label">
                    Home Address<i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="row">
                    {/* Country */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>
                          Country<i style={{ color: "red" }}>*</i>
                        </label>
                        <CountryDropdown
                         style={{ width: "100%", height:"40px" }}
                          value={home_country_address_country}
                          key={"home_country_address_country"}
                          onChange={(value) =>
                            set_home_country_address_country(value)
                          }
                          className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined"
                        />
                        {errors?.home_country_address_country && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.home_country_address_country[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* City */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>
                          City<i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={home_country_address_city}
                          placeholder="City"
                          onChange={(e) =>
                            set_home_country_address_city(e.target.value)
                          }
                        />
                        {errors?.home_country_address_city && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.home_country_address_city[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Postal / ZIP Code */}
                    <div className="col-4">
                      <div className="input-box">
                        <label>
                          ZIP / Postal Code <i style={{ color: "red" }}>*</i>
                        </label>
                        <Input
                          value={home_country_address_postal}
                          placeholder="Zip/Postal"
                          onChange={(e) =>
                            set_home_country_address_postal(e.target.value)
                          }
                          keyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.home_country_address_postal && (
                          <>
                            <span style={{ color: "red" }}>
                              {errors?.home_country_address_postal[0]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/*  Home Address1 */}
                <div className="col-4">
                  <div className="input-box">
                    <label>
                    Address Field 1<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                     value={home_country_address1}
                      placeholder="Address"
                      onChange={(e) => set_home_country_address1(e.target.value)}
                    />
                    {errors?.home_country_address1 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address1[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/*  Home Address2 */}
                <div className="col-4">
                  <div className="input-box">
                    <label>
                    Address Field 2
                    </label>
                    <Input
                    value={home_country_address2 ? home_country_address2 : ''}
                      placeholder="Address"
                      onChange={(e) => set_home_country_address2(e.target.value)}
                    />
                    {errors?.home_country_address2 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address2[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                  </div>

                  {/* Socile Security Number */}
                  {/* <div className='col-12'>
                            <div className='input-box'>
                                <label htmlFor="phone">Social Security Number</label>
                                <Input id='phone' onChange={(e) => set_social_security_number(e.target.value)} />
                                {errors?.social_security_number && <><span style={{ color: "red" }}>{errors?.social_security_number[0]}</span></>}
                            </div>
                        </div> */}
                </>
              </>

              {/* button */}
              <div className="row">
                <div className="col-6">
                  <div className="input-box" style={{display:"flex",gap:"15px"}}>
                    {loader ? (
                      <>
                        <Button type="primary" className="lusisbtn">
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: "12px",
                                  color: "#fff",
                                  marginRight: "5px",
                                }}
                              />
                            }
                          />{" "}
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={UPDATE_API} type="primary" className="lusisbtn">
                          Save
                        </Button><Button style={{width:"122px",height:"40px"}} onClick={(e) => navigate("/student-detail/" + id)} type="primary" danger>
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className='col-4'>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Registering For programme </Button>
                            </> : <>
                                <Button onClick={(record) => navigate('/add-enrollment/' + btoa(record.id))} type="primary">Register for programme</Button>
                            </>}
                        </div>
                    </div> */}
            </div>
          </>
        )}
      </Modal>
    
    </>
  );
};

export default EditStudentPopup;
