import { Button, Table, Tooltip } from 'antd'
import React from 'react'
import MultipleCoursePrint from '../../MultipleCoursePrint/multipleCoursePrint';
import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { BACKEND_URL } from '../../../../config/config';

const AdvancedAcademicPerformanceReportSummaryTable = ({table_list,academic_year,semester,entering_classification,programme_intended,gpa_range,programme_status,age,gender,identify}) => {


    const columns = [
        {
          title: "Student Name",
         render: (text, record) => {
            return (
              <>
                {record?.student?.first_name}{" "}{record?.student?.middle_name == "null"? "" : record?.student?.middle_name }{" "}{record?.student?.last_name}
              </>
            );
          },
        },
        {
          title: "Student Id",
          render: (text, record) => {
            return (
              <>
                {record?.student?.student_id}
              </>
            );
          },
        },
        {
          title: "Email",
          render: (text, record) => {
            return (
              <>
                {record?.student?.primary_email}
              </>
            );
          },
        },
        {
          title: "Age",
          render: (text, record) => {
            return (
              <>
               <span>{record?.age}</span>
              </>
            );
          },
        },
        {
            title: "Gender",
            render: (text, record) => {
              return (
                <>
                 <span style={{textTransform:"capitalize"}}>{record?.student?.gender}</span>
                </>
              );
            },
          },
          {
            title: "Ethnicity",
            render: (text, record) => {
              return (
                <>
                 {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
                </>
              );
            },
          },
          {
            title: "Course Details",
            render: (text, record) => {
              return (
                <>
               {record?.student_by_semester_courses_detail?.length > 0 &&
               <MultipleCoursePrint student_by_semester_courses_detail={record?.student_by_semester_courses_detail}  report_type = "academic_performance"/>
               }
                </>
              );
            },
          },
          {
            title: "Semester GPA",
            dataIndex: "gpa",
            key: "gpa",
          },
          {
            title: "CGPA",
            dataIndex: "cgpa",
            key: "cgpa",
          },
      ];
      
      function calculateAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
    
        // Adjust if the birthday hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }
    const DOWNLOADPDF = () =>{
  const token = JSON.parse(localStorage.getItem('sis_user_data')).token;
  const year = academic_year;
  const sem = semester;
  const progIntended = programme_intended;
  const enterClass = entering_classification;
  const gpa = gpa_range;
  const progStatus = programme_status;
  const personAge = age;
  const personGender = gender;
  const personIdentify = identify;

  window.open(
    `${BACKEND_URL}/csv-advance-academic-performance-reports/${token}/${year}/${sem}/${progIntended}/${enterClass}/${gpa}/${progStatus}/${personAge}/${personGender}/${personIdentify}`
  );

    }
  return (
   <>
   <div style={{display:"inline-block", float:"right", marginRight:"10px"}}>
                    {/* CSV Download button  */}
                    <Tooltip title="Download Academic Performance Report CSV">
                        <Button type='primary'
                        onClick={DOWNLOADPDF} 
                        ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                    </Tooltip>
                    {/* PDF Download button  */}
                    <Tooltip title="Download Academic Performance Report PDF">
                        <Button type='primary'
                         onClick={DOWNLOADPDF}  
                         ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
   <Table  className="vertical-align-top-table" columns={columns} pagination={true} dataSource={table_list}/>
   </>
  )
}

export default AdvancedAcademicPerformanceReportSummaryTable;