import ALLDemographicReportDownload from "./allDemographicReportDownload";
import DemographicReportSummaryGraph from "./demographicReportSummaryGraph";
import DemographicReportSummaryTable from "./demographicReportSummaryTable";

const DemographicReportVisualSection = () => {

    return (
        <>
            <div className="theme-content-left-head">
                <h2>Demographic Report</h2>
            </div>
            <br />
            <hr />
            <br />
            <h3>Demographic Summary Statistics Table </h3>
            <br />
            <br />
            <DemographicReportSummaryTable />
            <br />
            <hr />
            <br />
            <br />
            <div className="row">
                <div className="col-6">
                    <DemographicReportSummaryGraph />
                </div>
                <div className="col-6">
                    <ALLDemographicReportDownload />
                </div>
            </div>
        </>
    )
}

export default DemographicReportVisualSection;