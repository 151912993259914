import { Checkbox, Input, Table, notification, } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { DEMOGRAPHICS_REPORT_STATISTICS } from '../../../../apis/apis';

const DemographicReportSummaryTable = () => {
    const[demographicsummarytable, setDemographicSummaryTable] = useState([]);


useEffect(()=>{
VIEW_DEMOGRAPHIC_REPORT_STATISTICS();
},[])

const VIEW_DEMOGRAPHIC_REPORT_STATISTICS = async() =>{
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const VIEW_DEMOGRAPHIC_REPORT_STATISTICS_API_RESPONSE = await DEMOGRAPHICS_REPORT_STATISTICS(FORM_DATA);
    if(VIEW_DEMOGRAPHIC_REPORT_STATISTICS_API_RESPONSE?.data?.status)
        {
            setDemographicSummaryTable(VIEW_DEMOGRAPHIC_REPORT_STATISTICS_API_RESPONSE?.data?.statistics)
        }
}
    const columns = [
        {
            title: "No. of International Students",
            render: (text, record) => {
                return (
                    <>
                     <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            {record?.International_Students}
                            </span>
                    </>
                )
            }
        },

        {
            title: "No. of Domestic Students",
            render: (text, record) => {
                return (
                    <>
                      <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {record?.Domestic_Students}
                    </span>
                    </>
                )
            },
        },

        {
            title: "No. of Regular Students",
            render: (text, record) => {
                return (
                    <>
                     <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                     {record?.Regular_Students}
                     </span>
                    </>
                )
            },
        },

        {
            title: "No. of Athletic Students",
            render: (text, record) => {
                return (
                    <>
                     <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      {record?.Athletic_Students}
                      </span>
                    </>
                )
            },
        },

        {
            title: "No. of Male Students",
            render: (text, record) => {
                return (
                    <>
                    <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                     {record?.Male_Students}
                     </span>
                    </>
                )
            },
        },

        {
            title: "No. of Female Students",
            render: (text, record) => {
                return (
                    <>
                     <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                     {record?.female_Students}
                     </span>
                    </>
                )
            },
        },

        {
            title: "No. of Others Students",
            render: (text, record) => {
                return (
                    <>
                    <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {record?.other_Students}
                    </span>
                    </>
                )
            },
        },
        {
            title: "Total No. of Students",
            render: (text, record) => {
                return (
                    <>
                    <span style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {record?.total_Students}
                    </span>
                    </>
                )
            },
        },
    ];
    return (
        <>
            <Table columns={columns} dataSource={demographicsummarytable} />
        </>
    )
}

export default DemographicReportSummaryTable;