/* eslint-disable no-unused-vars */
import { Checkbox, Input, Select, notification } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import {
  COURSES_LIST,
  SEARCH_COURSE,
  SEMESTERS_COURSES_UPDATE,
  SEMESTERS_COURSES_DELETE,
} from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  UserOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";

const FacultyColumn = (props) => {
  const { calendar_id, sem_id, item, faculty_list } = props;
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [table_list_search, set_table_list_search] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "view") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.view
      );
    }
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const UPDATE_API = async (faculty) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(calendar_id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("course_id", item.course_id);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("course_type", item.course_type == null ? "" : item.course_type);
    FORM_DATA.append("enrollment_thresholds", item.enrollment_thresholds == null ? "" : item.enrollment_thresholds);
    FORM_DATA.append("room_id", item.room_id == null ? "" : item.room_id);
    FORM_DATA.append("timing", item.timing == null ? "" : item.timing);
    FORM_DATA.append("parent", item?.parent);
    // FORM_DATA.append('enrollment_students', item.enrollment_students);
    // FORM_DATA.append('scheduled', item.scheduled);
    // FORM_DATA.append('schedule_status', item.schedule_status);
    FORM_DATA.append("id", item.schedule_id);
    const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Faculty assigned successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };

  const set_sem_course = (value) => {
    set_loader(true);
    UPDATE_API(value);
  };

  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
          <Select
            disabled={
              // (parseInt(item.scheduled) > 0 ? true : false) ||
              (check_permissions("calendars_schedules", "create") ||
              check_permissions("calendars_schedules", "update")
                ? false
                : true)
            }
            value={item?.faculty}
            style={{ width: "130px" }}
            onChange={(value) => set_sem_course(value)}
            placeholder="--Select Faculty---"
          >
            {faculty_list?.length > 0 && (
              <>
               <Select.Option value="">To be allocated</Select.Option>
                {faculty_list?.map((itemv) => (
                  <>
                    <Select.Option value={itemv.id}>{itemv.name}</Select.Option>
                  </>
                ))}
              </>
            )}
          </Select>
        </>
      )}
    </>
  );
};

export default FacultyColumn;
