import { useEffect, useRef, useState } from "react";
import { ACADEMIC_REPORT_GRAPH, ALL_ACADEMIC_REPORT_GRAPH } from "../../../../apis/apis";
import Chart from 'chart.js/auto';

const AcademicReportSummaryGraph = () =>{
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: []
      });
    
      const VIEW_ALL_ACADEMIC_REPORT_GRAPH = async() => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const VIEW_ALL_ACADEMIC_REPORT_GRAPH_API_RESPONSE = await ALL_ACADEMIC_REPORT_GRAPH(FORM_DATA);
        if (VIEW_ALL_ACADEMIC_REPORT_GRAPH_API_RESPONSE?.data?.status) {
          setGraphData(VIEW_ALL_ACADEMIC_REPORT_GRAPH_API_RESPONSE?.data);
        }

      }

      useEffect(()=>{
        VIEW_ALL_ACADEMIC_REPORT_GRAPH();
      },[])

      const chartRef = useRef(null);

      useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
    
        const combinedChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: graphData?.programmes,
            datasets: [
              {
                label: 'Enrolled Students',
                data: graphData?.enrolled_students,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              },
              {
                label: 'Registered Students',
                data: graphData?.register_students,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
              },
              {
                label: 'New Students',
                data: graphData?.new_students,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
              },
              {
                label: 'Returning Students',
                data: graphData?.returning_students,
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
              },
              {
                label: 'Transfer In',
                data: graphData?.transfer_in,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
              },
              {
                label: 'Transfer Out',
                data: graphData?.transfer_out,
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
              },
              {
                label: 'Withdraw Out',
                data: graphData?.withdraw_out,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              },
              {
                label: 'Part Time',
                data: graphData?.part_time,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
              },
              {
                label: 'Full Time',
                data: graphData?.full_time,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
              },
              {
                label: 'Faculties',
                data: graphData?.faculties,
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
              },
            ]
          },
          options: {
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  boxWidth: 12, // Set your desired width for the color bar here
                  font: {
                    size: 12 // Set your desired font size here
                }
              }
              },
              title: {
                display: true,
                // text: 'Student Academic Report',
              },
            },
            responsive: true,
            scales: {
              x: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Program Code',
                  font: {
                    size: 14,
                    weight: 'bold',
                    family: 'Arial',
                    color: '#000'
                  }
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                   text: 'No. of Students',
                  font: {
                    size: 14,
                    weight: 'bold',
                    family: 'Arial',
                    color: '#000'
                  }
                }
              },
            },
          },
        });
    
        return () => {
          combinedChart.destroy();
        };
      }, [graphData]);
    
    return(
        <>
        <h3>Visualization Summary of Academic Report</h3>
         <div style={{ width: '100%', margin: '0 auto' }}>
        <canvas ref={chartRef}></canvas>
      </div>
        </>
    )
}

export default AcademicReportSummaryGraph;