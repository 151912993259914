import React from 'react'
import GradeListTable from './GradeListTable';

export default function SemesterGradeList({response_table}) {
   
    console.log(response_table);
  return (
        <>
            {response_table?.length > 0 ? <>
               {response_table.map((item) => (
                <>
                <GradeListTable semester={item.semester} total_credit_attempted_txn={item.total_credit_attempted_txn}  transferredCourses={item.transferredCourses} total_credit_given={item.total_credit_given} total_credit_earn_txn={item.total_credit_earn_txn}   grades_courses={item.grades_courses} />
                </>
               ))}
             
            </> : <>
            empty
            
            </>}
        </>
  )
}
