import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Checkbox, Input, Radio, Select, Spin, Table, notification, DatePicker, Space, Divider, List  } from 'antd';
import { InputNumber } from 'antd';
import { PlusCircleOutlined  } from '@ant-design/icons';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { EDIT_ENROLLMENT, CATEGORIES_LIST, VIEW_ENROLLMENT, STUDENTS_LIST, PROGRAMS_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import countryList from 'react-select-country-list';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);


const EditEnrollment = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [programme_id, set_programme_id] = useState('');
    const [student_id, set_student_id] = useState('');
    const [programme_list, set_programme_list] = useState([]);
    const [student_list, set_student_list] = useState([]);
    const [student_info, set_student_info] = useState([]);

    const PROGRAMS_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
        if (API_RESPONSE.data.status) {
            set_programme_list(API_RESPONSE.data.programmes);
        } else {
            set_loader(false);
        }
    }
    const STUDENTS_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await STUDENTS_LIST(FORM_DATA);
        if (API_RESPONSE.data.status) {
            set_student_list(API_RESPONSE.data.students);
            VIEW_API(API_RESPONSE.data.students)
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
   
    const VIEW_API = async (listOfStudent) => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_API_RESPONSE = await VIEW_ENROLLMENT(FORM_DATA);
        if (EDIT_API_RESPONSE?.data?.status) {
            set_programme_id(EDIT_API_RESPONSE.data.studentEnrollments.programme_id)
            set_student_id(EDIT_API_RESPONSE.data.studentEnrollments.student_id)
            set_student_info(listOfStudent.filter(obj => obj.id === EDIT_API_RESPONSE.data.studentEnrollments.student_id))
        }
    }
    useEffect(() => {
        PROGRAMS_LIST_API();
        STUDENTS_API();
    }, []);
    const UPDATE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        FORM_DATA.append('student_id', student_id);
        FORM_DATA.append('programme_id', programme_id);
        const API_RESPONSE = await EDIT_ENROLLMENT(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Student Enrollment Successfully updated.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/enrollments-list');
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Edit Registration</h3>
                </div>

                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                <div className='col-8'>
                    <div className='input-box'>
                        <label>Select Student<i style={{ color: "red" }}>*</i></label><br></br>
                        <Select
                            placeholder="Select Student"
                            style={{ width: '100%' }}
                            onChange={(value) => {set_student_id(value); set_student_info(student_list.filter(obj => obj.id === value))}}
                            value={student_id}
                        >
                            {student_list.map((item) => {
                                if (item.status === 1 && item.soft_delete === 0) {
                                    return (<><Select.Option value={item.id}>{item.first_name} {item.last_name}</Select.Option></>);
                                }
                            })}

                        </Select>
                        {errors?.programme_id && <><span style={{ color: "red" }}>{errors?.programme_id[0]}</span></>}
                    </div>
                    <div className='input-box'>
                        <label>Select Programme<i style={{ color: "red" }}>*</i></label><br></br>
                        <Select
                            placeholder="Select Programme"
                            style={{ width: '100%' }}
                            onChange={(value) => { set_programme_id(value); }}
                            value={programme_id}
                        >
                            {programme_list.map((item) => {
                                if (item.status === 1 && item.soft_delete === 0) {
                                    return (<><Select.Option value={item.id}>{item.title}</Select.Option></>);
                                }
                            })}

                        </Select>
                        {errors?.programme_id && <><span style={{ color: "red" }}>{errors?.programme_id[0]}</span></>}
                    </div>
                    <div className='input-box'>
                                {loader ? <>
                                    <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Edit Enrollment</Button>
                                </> : <>
                                    <Button type="primary" onClick={UPDATE_API}>Edit Enrollment</Button>
                                </>}
                            </div>
                </div>
                <div className='col-4'>
                    {student_info ? <>
                        <Divider orientation="left">Registration Details</Divider>
                        <List
                            bordered
                            dataSource={student_info}
                            renderItem={(item) => (
                                <List.Item>
                                    <b>Full Name:</b> {item.first_name}<br></br>
                                    <b>Email:</b> {item.email}<br></br>
                                    <b>Phone:</b> {item.phone}<br></br>
                                    <b>Application start on:</b> {item.application_start_on}<br></br>
                                    <b>Application ID:</b> {item.application_id}
                                </List.Item>
                            )}
                        />

                    </> : <>
                    
                    </>}
                </div>
            </div>
          
        </div>
    );
};

export default EditEnrollment;