import { useEffect, useState } from "react";
import SisLoader from "../../widgets/loader";
import { GET_PAYMENT_STATUS } from "../../apis/apis";



const OnlineFeePayment = ({id,set_resgistration_payment_props}) => {
    const [loader, set_loader] = useState(false);
    const [resgistration_payment, set_resgistration_payment] = useState('');
    const [application_fee, set_application_fee] = useState(0);
    const [coupon_applied, set_coupon_applied] = useState(0);

    useEffect(()=>{
        VIEW_PAYMENT_STATUS();
    },[])

    const VIEW_PAYMENT_STATUS = async() =>{
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', atob(id));
        const VIEW_PAYMENT_STATUS_API_RESPONSE = await GET_PAYMENT_STATUS(FORM_DATA);
        if(VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.status){
            set_resgistration_payment(VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment);
            const application_fee = (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.amount === null) || (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.amount === "null") ||
            (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.amount === "") ? 0 : 
            (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.amount)/100;
            set_application_fee(application_fee.toFixed(2));
            const coupon = (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.coupon === null) ||
            (VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.coupon === "null") ? 0 :
            VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment?.coupon;
            set_coupon_applied(coupon?.toFixed(2));
            set_resgistration_payment_props(VIEW_PAYMENT_STATUS_API_RESPONSE?.data?.resgistration_payment);
            set_loader(false);
        }
    }


    return (
        <>
            {loader ? <SisLoader />
                : <>
                    <div>
                        <br />
                        <hr />
                        <br />
                        <div className="theme-content-left-head">
                            <h3>Payment</h3>
                        </div>
                        <br />
                    </div>
                    {resgistration_payment?.payment_status === 1 ? 
                    <>
                    <div className="row">
                        <div className="col-4" style={{ margin: "10px 0" }}>
                            <span>Application Fee</span>
                            <span className="amount" style={{float:"right"}}>${application_fee}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4" style={{ margin: "10px 0" }}>
                            <span>
                                Coupon Applied 
                                {/* <span className="coupon">LU20OFF</span> */}
                            </span>
                            <span className="amount" style={{float:"right"}}>${coupon_applied}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                        <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4" style={{ margin: "10px 0" }}>
                            <span>Total Application Fee Received</span>
                            <span className="amount totalAmount" style={{float:"right"}}>${(application_fee-coupon_applied).toFixed(2)}</span>
                        </div>
                        <br />
                    </div>
                    </>
                    :
                    <span style={{color:"red"}}>Payment has not yet been made.</span>
                     }
                </>
            }
        </>
    );
}

export default OnlineFeePayment;