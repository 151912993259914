import AcademicReportSummaryGraph from "./academicReportSummaryGraph";
import AcademicReportSummaryTable from "./academicReportSummaryTable";
import ALLAcademicReportDownload from "./allAcademicReportDownload";

const AcademicReportVisualSection = () => {

    return (
        <>
            <div className="theme-content-left-head">
                <h2>Academic Report</h2>
            </div>
            <br />
            <hr />
            <br />
            <h3>Academic Summary Statistics Table </h3>
            <br />
            <br />
            <AcademicReportSummaryTable />
            <br />
            <hr />
            <br />
            <br />
            <div className="row">
                <div className="col-7">
                    <AcademicReportSummaryGraph />
                </div>
                <div className="col-5">
                    <ALLAcademicReportDownload />
                </div>
            </div>
        </>
    )
}

export default AcademicReportVisualSection;