import { Checkbox, Input, Table, notification, } from 'antd';
import { useEffect, useState } from 'react';
import { ATTENDANCE_REPORT_STATISTICS } from '../../../../apis/apis';

const AttendanceReportSummaryTable = () => {

    const[attendancesummarytable, setAttendanceSummaryTable] = useState([]);


    useEffect(()=>{
    VIEW_ATTENDANCE_REPORT_STATISTICS();
    },[])
    
    const VIEW_ATTENDANCE_REPORT_STATISTICS = async() =>{
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const VIEW_ATTENDANCE_REPORT_STATISTICS_API_RESPONSE = await ATTENDANCE_REPORT_STATISTICS(FORM_DATA);
        if(VIEW_ATTENDANCE_REPORT_STATISTICS_API_RESPONSE?.data?.status)
            {
                setAttendanceSummaryTable(VIEW_ATTENDANCE_REPORT_STATISTICS_API_RESPONSE?.data?.statistics)
            }
    }

    const columns = [
        {
            title: "No. of Excused Absence",
            render: (text, record) => {
                return (
                    <>
                 <span>{record?.excused_count}</span>
                    </>
                )
            }
        },

        {
            title: "No. of Unexcused Absence",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.unexcused_count}</span>
                    </>
                )
            },
        },

        {
            title: "No. of Present",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.present_count}</span>
                    </>
                )
            },
        },

        {
            title: "No. of Canceled Class",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.canceled_count}</span>
                    </>
                )
            },
        },
        {
            title: "Total No.of Students",
            render: (text, record) => {
                return (
                    <>
                    <span>
                    {record?.total_students}
                    </span>
                    </>
                )
            },
        },
    ];

    return(
        <>
          <Table columns={columns} dataSource={attendancesummarytable} />
        </>
    )
}

export default AttendanceReportSummaryTable;