import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Popconfirm,
  Table,
  notification,
  Input,
  Space,
  Tooltip,
} from "antd";
import "./style.css";
import { TableColumnsType, TableProps } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  FilePdfOutlined,
  EditOutlined,
  SmileOutlined,
  StopOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { COURSES_LIST, DELETE_COURSE, STATUS_COURSE } from "../../apis/apis";
import Highlighter from "react-highlight-words";
import { BACKEND_URL } from "../../config/config";
import { Pagination } from "antd";

const CoursesList = () => {
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Permissions state manage
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Form State
  const [title, set_title] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [popStatusDescription, set_popStatusDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, set_last_page] = useState(1);
  const [table_search, set_table_search] = useState([]);
  const [api_table_data, set_api_table_data] = useState([]);

  const handleStatusText = (id, status) => {
    if (status === 1) {
      set_popStatusDescription("Do you want to make this course Inactive");
    } else {
      set_popStatusDescription("Do you want to make this course Active");
    }
  };

  // Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = table_list.filter(
      (item) =>
        item.title
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase()) ||
        item.code
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase())
    );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      setFilteredData(filtered);
    }
  };

  const STATUS_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await STATUS_COURSE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Course status Successfully changed.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/courses-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await DELETE_COURSE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: API_RESPONSE?.data?.type ? "Success!!" : "Failed",
        description: API_RESPONSE.data.message,
        icon: (
          <SmileOutlined
            style={
              API_RESPONSE?.data?.type ? { color: "green" } : { color: "red" }
            }
          />
        ),
      });
      navigate("/courses-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Course Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    Table.EXPAND_COLUMN,
    {
      title: "Course Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => {
        if (record.status) {
          return <span className="table-status-activate">Active</span>;
        } else {
          return <span className="table-status-deactivate">Inactive</span>;
        }
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => {
        return (
          <>
            {check_permissions("courses", "status") && (
              <Popconfirm
                title="Change Status"
                description={popStatusDescription}
                onConfirm={() => {
                  STATUS_API(btoa(record.id));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  size="small"
                  style={{ backgroundColor: "#888888", marginRight: "5px" }}
                  onClick={() => handleStatusText(record.id, record.status)}
                >
                  <div class="tooltip">
                    {" "}
                    <StopOutlined />{" "}
                    <span class="tooltiptext">Status Change</span>{" "}
                  </div>
                </Button>
              </Popconfirm>
            )}

            {check_permissions("courses", "update") && (
              <Button
                type="primary"
                size="small"
                onClick={() => navigate("/edit-course/" + btoa(record.id))}
                style={{ marginRight: "5px" }}
              >
                <div class="tooltip">
                  {" "}
                  <EditOutlined /> <span class="tooltiptext">
                    Edit n View
                  </span>{" "}
                </div>
              </Button>
            )}
            {check_permissions("courses", "delete") && (
              <Popconfirm
                title="Change Status"
                description="Are you sure to delete this course?"
                onConfirm={() => {
                  DELETE_API(btoa(record.id));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" size="small" danger>
                  <div class="tooltip">
                    {" "}
                    <DeleteOutlined /> <span class="tooltiptext">
                      Delete
                    </span>{" "}
                  </div>
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];
  // DB list
  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("page", currentPage);
    
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var filteredData = API_RESPONSE?.data?.courses?.data?.filter(
        (item) => item.soft_delete === 0
      );
      set_table_list(filteredData);
      set_api_table_data(filteredData);
      setFilteredData(API_RESPONSE?.data?.courses?.data);
      setCurrentPage(API_RESPONSE?.data?.courses?.current_page)
      set_last_page(API_RESPONSE?.data?.courses?.last_page)
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    LIST_API();
  }, [currentPage]);
  const pagination_changes  = (page) => {
      setCurrentPage(page);
  };
  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Courses List</h3>
        </div>
        <div className="theme-content-right-head">
          {check_permissions("courses", "create") && (
            <Button
              type="primary"
              className="lusisbtn"
              style={{ width: "156px" }}
              onClick={() => navigate("/add-course")}
            >
              <PlusOutlined /> Add Course
            </Button>
          )}

          <Tooltip title="Download Course List CSV">
            <Button
              type="primary"
              onClick={() =>
                (window.location =
                  BACKEND_URL +
                  "/download-course/" +
                  JSON.parse(localStorage.getItem("sis_user_data")).token)
              }
              ghost
              style={{ marginLeft: "5px" }}
            >
              <CloudDownloadOutlined />
            </Button>
          </Tooltip>

          {/* PDF Download button  */}
          <Tooltip title="Download Course List PDF">
            <Button
              type="primary"
              onClick={() =>
                (window.location =
                  BACKEND_URL +
                  "/download-course-pdf/" +
                  JSON.parse(localStorage.getItem("sis_user_data")).token)
              }
              ghost
              style={{ marginLeft: "5px" }}
            >
              <FilePdfOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="theme-content-head">
        <div className="input-box">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
      </div>
      {loader ? (
        <SisLoader />
      ) : (
        <Table
          expandable={{
            expandedRowRender: (record) => (
              <>
                <div
                  style={{ backgroundColor: "#FFF", padding: "8px" }}
                  dangerouslySetInnerHTML={{ __html: record.description }}
                ></div>
              </>
            ),
          }}
          pagination={false}
          columns={columns}
          dataSource={filteredData}
        />
      )}

     <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={currentPage} total={last_page} /></div>
    </div>
  );
};

export default CoursesList;
