import React, { useState } from 'react';
import { UpOutlined, DownOutlined, SmileOutlined, InfoCircleFilled, } from '@ant-design/icons';
import "./attendance.css"
import { Input, Checkbox, Table, Select, Button, notification } from "antd";
import { useNavigate } from 'react-router-dom';

function RecordAttandanceList() {
    const navigate = useNavigate();
    const [isClassCancelled, setIsClassCancelled] = useState(false);
    const [reason, setReason] = useState("Present");
    const [markAttendance,setMarkAttendance] = useState("Attend Class");
    const dataSource = [
        {
            StudentName: 'Akash Gupta',
        }

    ]
    const columns = [
        {
            title: "Student Name",
            dataIndex: "StudentName",
            sorter: (a, b) => a.StudentName.localeCompare(b.StudentName),
            render: (text, record) => (
                <>
                   <div style={{ display: "flex", alignItems: "center" }}>
            <img
                style={{ width: "50px", borderRadius: "10px", marginRight: "10px"}}
                src="https://picsum.photos/id/1/200/200"
                alt="Profile Picture"
            />
            <label>{record.StudentName}</label>
        </div>
                </>
            )
        },
        {
            title: "Mark attendance",
            dataIndex: "markAttendance",
            key: "markAttendance",
            render: (text, record) => (
                <Select defaultValue={text} style={{ width: "220px" }} 
                value={markAttendance} 
                onChange={(value)=>setMarkAttendance(value)}>
                    <Select.Option value="Attend Class">Attend Class</Select.Option>
                    <Select.Option value="Absent Excused">Absent Excused</Select.Option>
                    <Select.Option value="Absent Unexcused">Absent Unexcused </Select.Option>
                </Select>
            ),
        },
        {
            title: "Reason",
            render: (text, record) => (
                <Select
                 value={reason} 
                 onChange={(value)=>setReason(value)}
                 style={{ width: "220px" }}>
                    <Select.Option value="Present">Present</Select.Option>
                    <Select.Option value="Medical oppoinment">Medical oppoinment</Select.Option>
                    <Select.Option value="Family Emergency">Family Emergency </Select.Option>
                </Select>
            ),
        },
        {
            title: "Comment",
            render: (title, record) => (
                <Input
                 style={{ width: "350px" }}
                    placeholder=" Comment....."
                />
            ),
        },

    ];
    const handleCheckboxChange = (e) => {
        setIsClassCancelled(e.target.checked);
        // If the class is canceled, default reason to "Present"
        if (e.target.checked) {
            setReason("Present");
            setMarkAttendance("Attend Class")
        }
        // else{
        //     setReason("Present");
        //     setMarkAttendance("Attend")
        // }
    };


    return (
        <>
            <div>
            <div className="row">
        <div>
            {/* <h3>{semester_details?.courses_code}</h3> */}
            {/* <h3>{semester_details?.courses_title}</h3> */}
        </div>
        <div>
           {/* {get_timing()} */}
        </div>
        <div>
           {/* {semester_details?.faculties_name} */}
        </div>
        </div>
                <hr />
                <div >
                    <Checkbox  onChange={handleCheckboxChange}>Class cancelled</Checkbox>
                    <br />
                    <div className="Lu_sis_">
                        <div className='theme-content-head' style={{ marginBottom: '0px' }}>
                            <div className='theme-content-left-head'>
                                <span style={{paddingRight:"10px"}}> <InfoCircleFilled style={{color: "#0000FF", fontSize:"18px", fontWeight:"bold"}} /></span><span><b> if a class set as cancelled</b></span>
                                <p    style={{marginLeft:"27px"}}>All The Student Will be Marked as present</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="theme-content-head">
                            <h3 style={{ padding: "10px" }}>Search Table</h3>
                            <div className="input-box">
                                <Input
                                    type="text"
                                    placeholder="Search..."

                                />
                            </div>
                        </div>
                    </div>
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                    <br />
                    <Button
                    onClick={()=>{
                        notification.open({
                            message: "Success!!",
                            description: "Students Attendance Marked Successfully",
                            icon: <SmileOutlined style={{ color: "green" }} />,
                          });
                        navigate('/faculty-modules-calender-list')
                    }}
                     type='primary'>Save </Button>
                </div>
            </div>
        </>
    );
}

export default RecordAttandanceList;
