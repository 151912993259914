import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, notification, Spin, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SIS_STORAGE, sisDateFormat, STUDENT_ADD_COURSE_REQUEST_STATUS, STUDENT_WITHDRAW_DETAIL } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";


const StudentWithdrawFromSemeterDetail = () => {
  const [loader, set_loader] = useState(false);
  const [page_loader, set_page_loader] = useState(false);
  const [errors, set_errors] = useState("");
  const [withdraw_course_table, set_withdraw_course_table] = useState([]);
  const [api_response, set_api_response] = useState("");
  const [request_date, set_request_date] = useState("");
  const { std_id, sem_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    VIEW_STUDENT_WITHDRAW_DETAIL();
  }, [page_loader]);
  const columns = [
    {
      title: "Course Name",
      dataIndex: "course_title",
      key: "course_title",
    },
    {
      title: "Course Code",
      dataIndex: "course_code",
      key: "course_code",
    },
    {
      title: "Total Credits",
      dataIndex: "course_units",
      key: "course_units",
    },
    {
      title: "Attempt Credits",
      render: (text, record) => {
        return (
          <>
            {record?.given_credits > 1
              ? record?.given_credits
              : "No Attempted Credits"}
          </>
        );
      },
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.room_name} ({record?.room_capacity})
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.faculty_name}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.course_status}
            </span>{" "}
          </>
        );
      },
    },
  ];
  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };
  const VIEW_STUDENT_WITHDRAW_DETAIL = async() => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id",atob(std_id));
    FORM_DATA.append("semester_id",atob(sem_id));
    const API_RESPONSE = await STUDENT_WITHDRAW_DETAIL(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_api_response(API_RESPONSE?.data);
      const createdAt = new Date();
      const dateOnly = createdAt.toISOString().split('T')[0];
      set_request_date(dateOnly);
      set_loader(false);
    }
   }
   const STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT = async (
    approve_status,
    approve_by
  ) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id", atob(std_id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("approve_status", approve_status);
    FORM_DATA.append("approve_by", approve_by);
    FORM_DATA.append("request_type", "withdraw");
    const API_RESPONSE = await STUDENT_ADD_COURSE_REQUEST_STATUS(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Status Updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_page_loader(page_loader == false ? true : false);
    } else {
      set_page_loader(page_loader == false ? true : false);
    }
  };


  return (
    <>
    {loader ? (
      <SisLoader />
    ) : (
      <>
        <div className="row">
          <div class="col-3">
            <div style={{ display: "flex" }}>
              <div className="input-box" style={{ position: "relative" }}>
                { api_response?.basicInformation?.image &&
                <img
                  src={ SIS_STORAGE + "/enrollments/" + api_response?.basic_information?.image}
                  alt="Profile"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                ></img>
                }
              </div>
              <div
                className="input-box"
                style={{ position: "relative", width: "200px" }}
              >
                <label style={{ fontSize: "20px", fontWeight: "500" }}>
                {api_response?.basic_information?.first_name}{" "}{api_response?.basic_information?.middle_name != "null" && api_response?.basic_information?.middle_name}{" "}{api_response?.basic_information?.last_name}
                </label>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Student ID</p>
              <h4 style={{ marginTop: "6px" }}>
              {api_response?.basic_information?.student_id}
              </h4>
            </div>
          </div>
          <div className="col-3">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Programme/Degree</p>
              <h4 style={{ marginTop: "6px" }}>{api_response?.program?.title}</h4>
            </div>
          </div>
        </div>
        <hr />
        <br />
        <div className="row">
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Date</p>
              <h4 style={{ marginTop: "6px" }}>
              {sisDateFormat(request_date)}
              </h4>
            </div>
          </div>
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Semester</p>
              <h4 style={{ marginTop: "6px" }}>{api_response?.semester?.title}</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Registration Number</p>
              <h4 style={{ marginTop: "6px" }}>{api_response?.semester_registration?.registration_no == "null" ? "-" : api_response?.semester_registration?.registration_no }</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Legal Status</p>
              <h4 style={{ marginTop: "6px" }}>{api_response?.basic_information?.student_category}</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="input-box" style={{ position: "relative" }}>
              <p>Financial Aid</p>
              <h4 style={{ marginTop: "6px" }}>{api_response?.basic_information?.financial_aid}</h4>
            </div>
          </div>
        </div>
        <hr />
        <br />
        <div className="row" style={{ margin: 0 }}>
          <h3>Request Details</h3>
        </div>
        <br />
        <div>
          <h4 style={{ color: "#8e8e8e" }}>
            Withdraw From Semester{" "}
            <span style={{ color: "#b64141" }}>
            Last day of add/drop without academic penalty-{" "}{sisDateFormat(api_response?.semester?.academic_penalty_start_date)}
            </span>
          </h4>
        </div>
        <br />
        <div>
          <h4 style={{ color: "#8e8e8e" }}>Registered Courses</h4>
        </div>
        <br />
        <Table
          columns={columns}
          pagination={false}
          dataSource={api_response?.withdraw_course_table?.filter((item)=> item?.course_status!="Not Registered" && item?.course_status!="")}
        />
        <br />
        <div className="row" style={{ margin: 0 }}>
          <h3>Request Reason</h3>
        </div>
        <br />
        <div className="row" style={{ margin: 0 }}>
          <div
            className="col-6"
            style={{ border: "1px solid #cacaca", borderRadius: "5px" }}
          >
            <div className="input-box">
              {/* {student_request?.comment} */}
            </div>
          </div>
        </div>
        <br />
        <div style={{ border: "1px solid #cacaca", borderRadius: "5px" }}>
          <div className="row" style={{ margin: "10px" }}>
          {api_response?.approval_instructor == 1 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CheckCircleOutlined
                      style={{
                        color: "#0f0",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Approved
                  </h3>
                  <br />
                </>
              )}
              {api_response?.approval_instructor == 2 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CloseCircleOutlined
                      style={{
                        color: "#ff0000",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Rejected
                  </h3>
                  <br />
                </>
              )}
            <h3 style={{ color: "#8e8e8e" }}>Approval by Instructor </h3>
          </div>
          <div
            className="row"
            style={{ display: "flex", margin: "10px", gap: "20px" }}
          >
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   disabled={
                    api_response?.approval_instructor === 1 ||
                    api_response?.approval_instructor === 2
                      ? true
                      : false
                  }
                  onClick={() =>
                    STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                      1,
                      "approval_instructor"
                    )
                  }
                    type="primary"
                  >
                    <CheckOutlined /> Approve
                  </Button>
                </>
              )}
            </div>
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary" className="reject">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Reject
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   disabled={
                    api_response?.approval_instructor === 1 ||
                    api_response?.approval_instructor === 2
                      ? true
                      : false
                  }
                  onClick={() =>
                    STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                      2,
                      "approval_instructor"
                    )
                  }
                    type="primary"
                    className="reject"
                  >
                    <CloseOutlined /> Reject
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <div style={{ border: "1px solid #cacaca", borderRadius: "5px" }}>
          <div className="row" style={{ margin: "10px" }}>
          {api_response?.approval_provost == 1 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CheckCircleOutlined
                      style={{
                        color: "#0f0",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Approved
                  </h3>
                  <br />
                </>
              )}
              {api_response?.approval_provost == 2 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CloseCircleOutlined
                      style={{
                        color: "#ff0000",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Rejected
                  </h3>
                  <br />
                </>
              )}
            <h3 style={{ color: "#8e8e8e" }}>Approval by Provost </h3>
          </div>
          <div
            className="row"
            style={{ display: "flex", margin: "10px", gap: "20px" }}
          >
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   disabled={
                    api_response?.approval_provost === 1 ||
                    api_response?.approval_provost === 2
                      ? true
                      : false
                  }
                  onClick={() =>
                    STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                      1,
                      "approval_provost"
                    )
                  }
                    type="primary"
                  >
                    <CheckOutlined /> Approve
                  </Button>
                </>
              )}
            </div>
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary" className="reject">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Reject
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   disabled={
                    api_response?.approval_provost === 1 ||
                    api_response?.approval_provost === 2
                      ? true
                      : false
                  }
                  onClick={() =>
                    STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                      2,
                      "approval_provost"
                    )
                  }
                    type="primary"
                    className="reject"
                  >
                    <CloseOutlined /> Reject
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <div style={{ border: "1px solid #cacaca", borderRadius: "5px" }}>
          <div className="row" style={{ margin: "10px" }}>
          {api_response?.approval_registrar == 1 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CheckCircleOutlined
                      style={{
                        color: "#0f0",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Approved
                  </h3>
                  <br />
                </>
              )}
              {api_response?.approval_registrar == 2 && (
                <>
                  <h3 style={{ color: "#8e8e8e" }}>
                    <CloseCircleOutlined
                      style={{
                        color: "#ff0000",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                    Rejected
                  </h3>
                  <br />
                </>
              )}
            <h3 style={{ color: "#8e8e8e" }}>Approval by Registrar</h3>
          </div>

          <div
            className="row"
            style={{ display: "flex", margin: "10px", gap: "20px" }}
          >
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   disabled={
                    api_response?.approval_registrar === 1 ||
                    api_response?.approval_registrar === 2
                      ? true
                      : false
                  }
                  onClick={() =>
                    STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                      1,
                      "approval_registrar"
                    )
                  }
                    type="primary"
                  >
                    <CheckOutlined /> Approve
                  </Button>
                </>
              )}
            </div>
            <div className="input-box" style={{ width: "100px" }}>
              {loader ? (
                <>
                  <Button type="primary" className="reject">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Reject
                  </Button>
                </>
              ) : (
                <>
                  <Button
                     disabled={
                      api_response?.approval_registrar === 1 ||
                      api_response?.approval_registrar === 2
                        ? true
                        : false
                    }
                    onClick={() =>
                      STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                        2,
                        "approval_registrar"
                      )
                    }
                    type="primary"
                    className="reject"
                  >
                    <CloseOutlined /> Reject
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="Lu_siss_">
          <h3
            className="first-letter-capitalize"
            style={{fontSize:"18px",fontWeight:500,color:"#8e8e8e",marginBottom:"10px"}}
          >
            Cost Adjustment
          </h3>
          <div className="row" style={{ margin: 0 }}>
            <div className="theme-content-head" style={{margin:0}}>
              <div className="theme-content-left-head">
               <p style={{fontSize:"16px",fontWeight:500,color:"#4b4b4b"}}>Regenerate new bill before approving the withdraw request.</p>
              </div>
              <div className="theme-content-right-head">
                <Button
                  style={{
                    fontSize: "11px",
                    height: "22px",
                    padding: "2px 15px",
                    borderRadius: "4px",
                    backgroundColor: "#b64141",
                  }}
                  type="primary"
                  onClick={() => navigate(
                    "/genrate-billing/" +
                      btoa(api_response?.semester?.calendar_id) +
                      "/" +
                      sem_id +
                      "/" +
                      std_id
                  )}
                >
                  Re-genrate bill
                </Button>
              </div>
            </div>
          </div>
          <br />
          <div style={{ border: "1px solid #cacaca", borderRadius: "5px" }}>
            <div className="row" style={{ margin: "10px" }}>
            {api_response?.approval_accounts_department == 1 && (
                  <>
                    <h3 style={{ color: "#8e8e8e" }}>
                      <CheckCircleOutlined
                        style={{
                          color: "#0f0",
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      />
                      Approved
                    </h3>
                    <br />
                  </>
                )}
                {api_response?.approval_accounts_department == 2 && (
                  <>
                    <h3 style={{ color: "#8e8e8e" }}>
                      <CloseCircleOutlined
                        style={{
                          color: "#ff0000",
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      />
                      Rejected
                    </h3>
                    <br />
                  </>
                )}
              <h3 style={{ color: "#8e8e8e" }}>
                Approval by Accounts Department{" "}
              </h3>
            </div>
            <div
              className="row"
              style={{ display: "flex", margin: "10px", gap: "20px" }}
            >
              <div className="input-box" style={{ width: "100px" }}>
                {loader ? (
                  <>
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Approve
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                    disabled={
                      api_response?.approval_accounts_department === 1 ||
                      api_response?.approval_accounts_department === 2
                        ? true
                        : false
                    }
                    onClick={() =>
                      STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                        1,
                        "approval_accounts_department"
                      )
                    }
                      type="primary"
                    >
                      <CheckOutlined /> Approve
                    </Button>
                  </>
                )}
              </div>
              <div className="input-box" style={{ width: "100px" }}>
                {loader ? (
                  <>
                    <Button type="primary" className="reject">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Reject
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                     disabled={
                      api_response?.approval_accounts_department === 1 ||
                      api_response?.approval_accounts_department === 2
                        ? true
                        : false
                    }
                    onClick={() =>
                      STUDENT_ADD_COURSE_REQUEST_STATUS_SUBMIT(
                        2,
                        "approval_accounts_department"
                      )
                    }
                      type="primary"
                      className="reject"
                    >
                      <CloseOutlined /> Reject
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row" style={{ margin: 0, lineHeight: "28px" }}>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            There is a $20.00 fee for each add or drop transaction.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            {" "}
            A late fee of $50.00 will be charged for any transaction of change
            in registration.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            Please pay the necessary fees to the Accounting Office.
          </p>
          <h3 style={{ color: "#4b4b4b", fontWeight: "600" }}>
            Please read and follow the procedure below.
          </h3>
          <br />
          <h4 style={{ color: "#4b4b4b", fontWeight: "600" }}>
            PROCEDURE FOR CHANGE IN REGISTRATION
          </h4>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            1. Students must complete this form including the registration
            number and student ID number. Incomplete forms will not be
            processed.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            2. In the Spring 2024 semester the last day classes may be
            added/dropped without academic penalty is February 5, 2024.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            3. A student’s choice of studies, once made and filed, is expected
            to be permanent for the term. The student may request registration
            changes for a fee and with a reason. The Registrar can make the
            change through the specified date indicated in the academic
            calendar (normally before the third-class meeting) if space is
            available and all prerequisites were taken, approval by the
            instructor is required after the first-class meeting.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            4. Later the specified date deadline, approvals by the instructor
            and the Provost with a written confirmation of the student’s
            understanding that missing classes may negatively influence the
            course final grade are required to add a course.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            5. A course may be dropped at any time for the same fee. Student
            withdrawing from a course before specified deadline for add/drop
            classes will get the full refund and the dropped course will be
            removed from his/her records, withdrawing after that deadline but
            before 75% of the course completion will result in “W” symbol and
            a partial tuition refund following the refund procedure. Students
            withdrawing after the 75% of the course completion and before the
            final are not eligible for a refund and will receive “Y” symbol.
            Students cannot withdraw after the final examination.
            International and financial aid students can drop courses only
            continuing to satisfy required credit loads.
          </p>
          <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
            6. Fees for Change in Registration must be paid to the Accounting
            Office then this form must be returned to the Registrar’s Office.
            Students must provide their initial registration form when they
            submit this form for the payment.
          </p>
        </div>
      </>
    )}
  </>
  )
}

export default StudentWithdrawFromSemeterDetail;