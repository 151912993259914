import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import React from "react";
import { BACKEND_URL } from "../../../../config/config";

const AdvancedStdDemoReportSummaryTable = ({table_list,academic_year,semester,entering_classification,programme_intended,
  student_category,student_athlete,admission_status,student_type,home_country_address_state,home_country_address_country,citizenship,
  age,gender,identify
 }) => {
  const columns = [
    {
      title: "Student Name",
      render: (text, record) => {
        return (
          <>
            {record?.student?.first_name}{" "}
            {record?.student?.middle_name == "null"
              ? ""
              : record?.student?.middle_name}{" "}
            {record?.student?.last_name}
          </>
        );
      },
    },
    {
      title: "Student Id",
      render: (text, record) => {
        return <>{record?.student?.student_id}</>;
      },
    },
    {
      title: "Age",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {calculateAge(record?.student?.birth_date)}
            </span>
          </>
        );
      },
    },
    {
      title: "Gender",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.student?.gender}
            </span>
          </>
        );
      },
    },
    {
      title: "Ethnicity",
      render: (text, record) => {
        return (
          <>{record?.student?.ethnicity ? record?.student?.ethnicity : "-"}</>
        );
      },
    },
    {
      title: "Program",
      render: (text, record) => {
        return (
          <>{record?.program?.title}</>
        );
      },
    },
    {
      title: "Admission Status",
      render: (text, record) => {
        return (
          <>{record?.semester_registrations?.admission_status}</>
        );
      },
    },
    {
      title: "Student Category",
      render: (text, record) => {
        return (
          <>{record?.student?.student_category == "null" || record?.student?.student_category== null ? "-" : record?.student?.student_category}</>
        );
      },
    },
    {
      title: "Home State",
      render: (text, record) => {
        const Home_Country_Address = JSON.parse(record?.student?.home_country_address);
        return (
          <>{Home_Country_Address?.home_country_address_state}</>
        );
      },
    },
    {
      title: "Home Country",
      render: (text, record) => {
        const Home_Country_Address = JSON.parse(record?.student?.home_country_address);
        return (
          <>{Home_Country_Address?.home_country_address_country}</>
        );
      },
    },
    {
      title: "Citizenship",
      render: (text, record) => {
        return (
          <>{record?.student?.citizenship}</>
        );
      },
    },
  ];
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  return (
    <>
      <div
        style={{ display: "inline-block", float: "right", marginRight: "10px" }}
      >
        {/* CSV Download button  */}
        <Tooltip title="Download Student Demographics Report CSV">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '/csv-student-demographic-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ entering_classification +'/'+ programme_intended +'/'+ student_category +'/'+ student_athlete +'/'+ admission_status+'/'+ student_type +'/'+ home_country_address_state+'/'+ home_country_address_country  +'/'+ citizenship +'/'+ age+'/'+ gender +'/'+ identify)}
            ghost
            style={{ marginLeft: "5px" }}
          >
            <CloudDownloadOutlined />
          </Button>
        </Tooltip>
        {/* PDF Download button  */}
        <Tooltip title="Download Student Demographics Report PDF">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '/pdf-student-demographic-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ entering_classification +'/'+ programme_intended +'/'+ student_category +'/'+ student_athlete +'/'+ admission_status+'/'+ student_type +'/'+ home_country_address_state+'/'+ home_country_address_country  +'/'+ citizenship +'/'+ age+'/'+ gender +'/'+ identify)}
            ghost
            style={{ marginLeft: "5px" }}
          >
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </div>
      <Table columns={columns} pagination={true} dataSource={table_list} />
    </>
  );
};

export default AdvancedStdDemoReportSummaryTable;
