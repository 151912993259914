import React from 'react';
import AcademicGraphReports from './dashboardgraphs/AcademicGraphReports';
import DemographicsGraphReports from './dashboardgraphs/DemographicGraphReports';
import AttendanceGraphReports from './dashboardgraphs/AttendanceGraphReports';

const Dashboard = () => {
  return (
    <>
      <div class="row">
        <div class="col-7">
          <AcademicGraphReports />
        </div>
        <div class="col-5">
          <DemographicsGraphReports />
        </div>
      </div>
    <hr style={{margin:"10px 0px"}}/>
      <div class="row">
        <div class="col-7">
          <AttendanceGraphReports />
        </div>
        <div class="col-5">

        </div>
      </div>



    </>
  );
};

export default Dashboard;