import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { BACKEND_URL } from '../../../../config/config';


const ALLDemographicReportDownload = () => {
    return (
        <>
            <h3>Download Demographic Reports</h3>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Report</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Student Category Report</td>
                        <td>
                        <Tooltip title="Download Student Category Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-student-category-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                           <Tooltip title="Download Student Category Report PDF">
                                <Button type='primary' onClick={() => window.open(BACKEND_URL + '/student-category-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token)}  ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Student Type Report</td>
                        <td>
                        <Tooltip title="Download Student Type Report CSV">
                            <Button type='primary'
                              onClick={() => window.location = BACKEND_URL + '/csv-student-type-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                           <Tooltip title="Download Student Type Report PDF">
                                <Button type='primary' onClick={() => window.open(BACKEND_URL + '/student-type-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token)}  ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}> Student Gender Report </td>
                        <td>
                        <Tooltip title="Download Student Gender Report CSV">
                            <Button type='primary'
                               onClick={() => window.location = BACKEND_URL + '/csv-student-gender-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                           <Tooltip title="Download Student Gender Report PDF">
                                <Button type='primary' onClick={() => window.open(BACKEND_URL + '/student-gender-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token)}  ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>

        </>
    );
};

export default ALLDemographicReportDownload;

