import React, { useEffect, useState } from "react";
import { ADVANCE_ACADEMIC_PERFORMANCE_REPORT, ADVANCE_FINANCIAL_REPORT, CALENDARS_LIST, PROGRAMS_LIST } from "../../../../apis/apis";
import { useNavigate } from "react-router-dom";
import SisLoader from "../../../../widgets/loader";
import { Button, Select } from "antd";
import AdvancedFinancialReportSummaryTable from "./advancedFinancialReportSummaryTable";

const AdvancedFinancialReportVisualSection = () => {
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [entering_classification, set_entering_classification] = useState("");
  const [academic_year, set_academic_year] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [payment_status, set_payment_status] = useState("");
  const [payment_method, set_payment_method] = useState("");
  const [account_balance_status, set_account_balance_status] = useState("");
  const [fees_type, set_fees_type] = useState("");
  const [academic_year_list, set_academic_year_list] = useState([]);
  const [semester, set_semester] = useState("");
  const [sem_list, set_sem_list] = useState([]);
  const [programme_list, set_programme_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);
  
  const navigate = useNavigate();

  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_academic_year_list(API_RESPONSE?.data?.calendars);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  // const calender_change_function = (value) => {
  //   set_semester("");
  //   set_errors("");
  //   const index = academic_year_list.findIndex((item) => item.id === value);
  //   set_academic_year(value);
  //   set_calender_index(index);
  //   set_sem_list(academic_year_list[index].semesters);
  // };

  const calender_change_function = (value) => {
    set_semester("");
    set_errors("");
    const index = academic_year_list.findIndex((item) => item.id === value);
  
    if (index !== -1) { // Only proceed if a valid academic year is selected
      set_academic_year(value);
      set_calender_index(index);
      set_sem_list(academic_year_list[index]?.semesters || []);  // Ensure semesters exist or use an empty array
    } else {
      // Handle case when custom option (e.g., "Please select academic calendar") is selected
      set_academic_year("");  // Reset academic year
      set_calender_index(-1);  // Reset index
      set_sem_list([]);  // Reset semesters list
    }
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };

  const GENERATE_ADVANCE_FINANCIAL_REPORT = async()=>{
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calender_id", academic_year);
    FORM_DATA.append("semester_id", semester);
    FORM_DATA.append("entering_classification", entering_classification);
    FORM_DATA.append("program_id", programme_intended);
    FORM_DATA.append("payment_status", payment_status);
    FORM_DATA.append("payment_method", payment_method);
    FORM_DATA.append("fees_type", fees_type);
    FORM_DATA.append("account_balance_status", account_balance_status);
    const API_RESPONSE = await ADVANCE_FINANCIAL_REPORT(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_table_list(API_RESPONSE?.data?.report);
      set_loader(false);
    }
    else{
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  }


  useEffect(() => {
    LIST_API();
    PROGRAMS_LIST_API();
  }, [academic_year]);
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="theme-content-left-head">
            <h2>Financial Reports (Student Accounts)</h2>
            <br />
            <p>
              Monitor student account balances, tuition payments, and
              outstanding fees.
            </p>
          </div>
          <br />
          <hr />
          <br />
          <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#4b4b4b" }}>
            Filters
          </h3>
          <br />
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Academic Year<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                  placeholder="--Select Academic Year--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={calender_change_function}
                  value={
                    academic_year ? parseInt(academic_year) : academic_year
                  }
                  options={[
                    { value: "", label: "Please select academic calendar" }, // Custom option
                    ...academic_year_list.map((item) => ({
                      value: item.id,
                      calender_id: item.id,
                      label: item.title,
                    })),
                  ]}
                />
                {errors?.calender_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Academic Year field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Semester<i style={{ color: "red" }}>*</i>
                </label>
                <br />

                <Select
                  placeholder="--Select Semester--"
                  style={{ width: "100%", height: "40px" }}
                  disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                  onChange={(value) => {
                    set_semester(value);
                  }}
                  value={semester}
                  options={[
                    { value: "", label: "Please select semester" }, // Static custom option
                    ...sem_list.map((item) => ({
                      value: item?.id,
                      label: item?.title,
                    })),
                  ]}
                />

                {errors?.semester_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The semester field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
              <div className="col-3">
                <div className="input-box" style={{ width: "100%" }}>
                  <label>
                    Student Level<i style={{ color: "red" }}>*</i>
                  </label>
                  <br />
                  <Select
                    onChange={(value) => {
                      set_entering_classification(value);
                    }}
                    value={entering_classification}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Select Entering Classifications"
                    required
                  >
                      <Select.Option value="">
                     Please select student level
                    </Select.Option>
                    <Select.Option value="undergraduate">
                      Undergraduate
                    </Select.Option>
                    <Select.Option value="graduate">Graduate</Select.Option>
                    <Select.Option value="doctoral">Doctoral</Select.Option>
                  </Select>
                  {errors?.entering_classification && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.entering_classification[0]}
                      </span>
                    </>
                  )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{width:"100%"}}>
                <label>
                  Program / Major<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                  value={programme_intended}
                  placeholder="--Select programme--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_programme_intended(value)}
                >
                   <Select.Option value="">
                   Please select program
                  </Select.Option>
                  {programme_list.map((item) => {
                    if (item.status === 1 && item.soft_delete === 0) {
                      return (
                        <>
                          <Select.Option value={item.id}>
                            {item.title}
                          </Select.Option>
                        </>
                      );
                    }
                  })}
                </Select>
                {errors?.program_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Program field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
          <div className="col-3">
            <div className="input-box">
              <label>Payment Status</label>
              <br></br>
              <Select
                value={payment_status}
                style={{ width: "100%", height: "40px" }}
                onChange={(value) => set_payment_status(value)}
                placeholder="--Select Payment Status--"
              >
                 <Select.Option value="">
                   Please select payment status
                  </Select.Option>
                <Select.Option value={2}>Fully Paid</Select.Option>
                <Select.Option value={3}>Partial Paid</Select.Option>
                <Select.Option value={4}>Not Paid</Select.Option>
              </Select>
              {errors?.payment_status && (
                <>
                  <span style={{ color: "red" }}>{errors?.payment_status[0]}</span>
                </>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-box">
              <label>Payment Method</label>
              <br></br>
              <Select
                value={payment_method}
                style={{ width: "100%", height: "40px" }}
                onChange={(value) => set_payment_method(value)}
                placeholder="--Select Payment Method--"
              >
                 <Select.Option value="">
                   Please select payment method
                  </Select.Option>
                <Select.Option value="Cash">Cash</Select.Option>
                <Select.Option value="Credit Card">Credit Card</Select.Option>
                <Select.Option value="Payment Plan">Payment Plan</Select.Option>
              </Select>
              {errors?.payment_method && (
                <>
                  <span style={{ color: "red" }}>{errors?.payment_method[0]}</span>
                </>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-box">
              <label>Fees Type</label>
              <br></br>
              <Select
                value={fees_type}
                style={{ width: "100%", height: "40px" }}
                onChange={(value) => set_fees_type(value)}
                placeholder="--Select Fees Type--"
              >
                 <Select.Option value="">
                   Please select fees type
                  </Select.Option>
                {/* <Select.Option value="tution">Tution</Select.Option> */}
                {/* <Select.Option value="semester_registrations_fees">Semester Registrations Fees</Select.Option> */}
                <Select.Option value="late_registrations_fees">Late Registrations Fees</Select.Option>
                <Select.Option value="late_charges">Late Charges Fees</Select.Option>
                <Select.Option value="material_fee">Material fees</Select.Option>
                <Select.Option value="test_fee">Test Fees</Select.Option>
                <Select.Option value="student_body">student Body Fees</Select.Option>
                <Select.Option value="id_card_fee">Id Card Fees</Select.Option>
                <Select.Option value="note_fee">Note Fees</Select.Option>
                <Select.Option value="publication_fee">Publication Fees</Select.Option>
                <Select.Option value="ecg_lab_fee">ECG Lab Fees</Select.Option>
                <Select.Option value="tuition_recover_fee">Tuition Recover Fees</Select.Option>
                <Select.Option value="publication_fee">Publication Fees</Select.Option>
                <Select.Option value="library_fee">Library Fees</Select.Option>
                <Select.Option value="lab_fee">Lab Fees</Select.Option>
              </Select>
              {errors?.fees_type && (
                <>
                  <span style={{ color: "red" }}>{errors?.fees_type[0]}</span>
                </>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-box">
              <label>Account Balance Status</label>
              <br></br>
              <Select
                value={account_balance_status}
                style={{ width: "100%", height: "40px" }}
                onChange={(value) => set_account_balance_status(value)}
                placeholder="--Select Account Balance--"
              >
                <Select.Option value="">
                   Please select account balance
                  </Select.Option>
                <Select.Option value="zero balance">Zero Balance</Select.Option>
                <Select.Option value="positive balance">Positive Balance</Select.Option>
                <Select.Option value="overdue">Overdue</Select.Option>
              </Select>
              {errors?.account_balance_status && (
                <>
                  <span style={{ color: "red" }}>{errors?.account_balance_status[0]}</span>
                </>
              )}
            </div>
          </div>
          </div>
          <br />
        <Button
           onClick={GENERATE_ADVANCE_FINANCIAL_REPORT}
          style={{
            backgroundColor: "#4b4b4b",
            color: "#ffffff",
            width: "156px",
            height: "40px",
          }}
          type="primary"
        >
          Generate Report
        </Button>
        <br />
        <br />
        {table_list?.length > 0 && (
          <AdvancedFinancialReportSummaryTable
           table_list={table_list}
           academic_year={academic_year}
           semester={semester}
           entering_classification={entering_classification}
           programme_intended={programme_intended}
           payment_status={payment_status}
           payment_method={payment_method}
           fees_type={fees_type}
           account_balance_status={account_balance_status}
            />
        )}
        </>
      )}
    </>
  );
};

export default AdvancedFinancialReportVisualSection;
