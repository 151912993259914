import { DeleteOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Select, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { REASONS_DELETE, REASONS_UPDATE, VIEW_REASON } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
import { useNavigate } from "react-router-dom";

export default function ReasonRow({ id, history, item, set_reason_row_loader, reason_row_loader }) {
  const navigate = useNavigate();
  const [status, set_status] = useState(0);
  const [faculty_access, set_faculty_access] = useState(" ");
  const [reason_title, set_reason_title] = useState(" ");
  const [reason_type, set_reason_type] = useState(" ");
  const [isExcused, set_isExcused] = useState(" ");
  const [isUsedinCalculations, set_isUsedinCalculations] = useState(" ");
  const [isFullDay, set_isFullDay] = useState(" ");
  const [loader, set_row_loader] = useState(true);
  const [cat_id, set_cat_id] = useState(' ');
  const [errors, set_errors] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  // const VIEW_API = async () => {
  //   const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
  //   const FORM_DATA = new FormData();
  //   FORM_DATA.append("id", id);
  //   FORM_DATA.append("token", USER_DATA.token);
  //   const RESPONSE = await VIEW_REASON(FORM_DATA);
  //   if (RESPONSE?.data?.status) {
     
      
  //   } else {
  //     set_errors(RESPONSE.data.errors);
  //     set_row_loader(false)
     
  //   }
  // }
  useEffect(() => {
    set_status(item?.status);
    set_faculty_access(item?.faculty_access);
    set_reason_title(item?.reason_title);
    set_reason_type(item?.reason_type);
    set_isExcused(item?.isExcused);
    set_isUsedinCalculations(item?.isUsedinCalculations);
    set_isFullDay(item?.isFullDay);
    set_cat_id(item?.attendance_category_id);
    set_row_loader(false)
    // VIEW_API();
  }, [loader])
  const UPDATE_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("status", status);
    FORM_DATA.append("faculty_access", faculty_access);
    FORM_DATA.append("reason_title", reason_title != 'null' &&  reason_title ? reason_title : ' ');
    FORM_DATA.append("reason_type", reason_type);
    FORM_DATA.append("isExcused", isExcused);
    FORM_DATA.append("isUsedinCalculations", isUsedinCalculations);
    FORM_DATA.append("isFullDay", isFullDay);
    const API_RESPONSE = await REASONS_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Attendance Category Reason Detail Updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_errors([]);
      reason_row_loader ? set_reason_row_loader(false) : set_reason_row_loader(true)
      // navigate("/courses-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };

  const DELETE_API = async () => {
    set_row_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await REASONS_DELETE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Reason delete successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      reason_row_loader ? set_reason_row_loader(false) : set_reason_row_loader(true)
      window.location = "/attendance-category/" + btoa(cat_id)
    //  navigate("/attendance-category/" + btoa(cat_id));
    } else {
     
    }
  };
  return (
    <>
        <div className="reason-row">
        <div className="reason-column">
          <Input
            value={reason_title && reason_title}
            onChange={(e) => set_reason_title(e.target.value)}
          />
          {errors?.reason_title && (
            <>
              <span style={{ color: "red" }}>{errors?.reason_title[0]}</span>
            </>
          )}
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={parseInt(status)}
            onChange={(value) => set_status(value)}
          >
            <Select.Option value={1}>Active</Select.Option>
            <Select.Option value={0}>Inactive</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={parseInt(faculty_access)}
            onChange={(value) => set_faculty_access(value)}
          >
            <Select.Option value={1}>Yes</Select.Option>
            <Select.Option value={0}>No</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={parseInt(reason_type)}
            onChange={(value) => {
              set_isExcused(2)
              set_isFullDay(2)
              set_reason_type(value)
            }}
          >
            <Select.Option value={1}>Present</Select.Option>
            <Select.Option value={0}>Absence</Select.Option>
            <Select.Option value={2}>Tardy</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={parseInt(isExcused)}
            onChange={(value) => set_isExcused(value)}
            disabled={parseInt(reason_type) ==  1 ? true : false}
          >
            <Select.Option value={1}>Excused</Select.Option>
            <Select.Option value={0}>Unexcused</Select.Option>
            <Select.Option value={2}>N/A</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={isUsedinCalculations}
            onChange={(value) => set_isUsedinCalculations(value)}
          >
            <Select.Option value={1}>Yes</Select.Option>
            <Select.Option value={0}>No</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
          <Select
            style={{ width: "100%" }}
            value={isFullDay}
            onChange={(value) => set_isFullDay(value)}
            disabled={parseInt(reason_type) ==  1 ? true : false}
          >
            <Select.Option value={1}>Full Day</Select.Option>
            <Select.Option value={0}>Half Day</Select.Option>
            <Select.Option value={2}>N/A</Select.Option>
          </Select>
        </div>
        <div className="reason-column">
        {check_permissions('attendance_category_reasons', 'update') &&
           <Button type="primary" size="small" onClick={UPDATE_API} style={{ marginRight:"5px" }}>Save</Button>}
           {check_permissions('attendance_category_reasons', 'delete') &&
          <Popconfirm
            title="Delete Student"
            description="Are you sure to delete this student?"
            onConfirm={() => {
              DELETE_API();
            }}
            okText="Yes"
            cancelText="No"
          >
           
            <Button type="primary" size="small" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>}
        </div>
      </div>
    </>
  );
}
