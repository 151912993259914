import React, { useEffect, useState } from "react";
import "./grading.css";
import { useNavigate, useParams } from "react-router-dom";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { ACADEMIC_SEMESTER, CALENDARS_LIST, FACULTIES_LIST, SEMESTER_WITH_REGISTERED_COURSE, sisDateFormat } from "../../apis/apis";
import { Button, Input, Select, Table } from "antd";
// import grading from "./Gradingobject"

function Grading() {
  const navigate = useNavigate();
  const { semester_id } = useParams();

  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [history_semester_id, set_history_semester_id] = useState('');
  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const [facultytablelist, setFacultyTableList] = useState([]);
  const [faculty_id, set_faculty_id] = useState('');
  const [grade_plan, set_grade_plan] = useState('');
  const [grade_plan_active, set_grade_plan_active] = useState(0);
  const [calendars_list, set_calendars_list] = useState([]);
  const [semesters_list, set_semesters_list] = useState([]);
  const [active_calender_id, set_active_calender_id] = useState();
  const [active_sem_id, set_active_sem_id] = useState();
  const [courses_list, set_courses_list] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');



  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
    }
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const Calendar_API_RESPONSE = await CALENDARS_LIST(FORM_DATA);

    if (Calendar_API_RESPONSE?.data?.status) {
      // set_loader(false);
      set_calendars_list(Calendar_API_RESPONSE?.data?.calendars);
      set_active_calender_id(Calendar_API_RESPONSE?.data?.calendars[0]?.id)
      set_semesters_list(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters)
      set_active_sem_id(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      if(Calendar_API_RESPONSE?.data?.calendars[0]?.id && Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id)
      {
        SEMESTER_WITH_REGISTERED_COURSE_LIST(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id,"");
      }

    } else {
      // set_loader(false);
    }
  }


  const ACADEMIC_SEMESTER_LIST = async () => {
    set_loader(true)
    const FORM_DATA = new FormData();
    const API_RESPONSE = await ACADEMIC_SEMESTER(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_semesters_list(API_RESPONSE?.data?.academic_semester);
      if (semester_id) {
        set_history_semester_id(atob(semester_id))
        SEMESTER_WITH_REGISTERED_COURSE_LIST(atob(semester_id), '')
      } else {
        if (API_RESPONSE?.data?.academic_semester?.length > 0) {
          set_history_semester_id(API_RESPONSE?.data?.academic_semester[0].id)
          SEMESTER_WITH_REGISTERED_COURSE_LIST(API_RESPONSE?.data?.academic_semester[0].id, '')
        }
      }
    } else {
    }
  };

  const SEMESTER_WITH_REGISTERED_COURSE_LIST = async (semester_id, faculty_id_value) => {
    set_loader(true);
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', semester_id);
    FORM_DATA.append('faculty_id', faculty_id_value);
    const API_RESPONSE = await SEMESTER_WITH_REGISTERED_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_faculty_id(faculty_id_value);
      set_courses_list(API_RESPONSE?.data?.courses);
      set_grade_plan(API_RESPONSE?.data?.grade_plan);
      set_grade_plan_active(API_RESPONSE?.data?.grade_plan_active);
      setFilteredData(API_RESPONSE?.data?.courses);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };


  const FACULTY_LIST_API = async () => {
    const FORM_DATA = new FormData();
    const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    FORM_DATA.append('token', FACULTIES_DATA.token);
    const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      setFacultyTableList(API_RESPONSE?.data?.faculty?.data);
    } else {
    }
  };

  // useEffect(() => {
  //   ACADEMIC_SEMESTER_LIST();
  //   FACULTY_LIST_API();
  // }, [semester_id]);


  useEffect(() => {
    LIST_API();
    FACULTY_LIST_API();
  }, []);


  const columns = [
    {
      title: "Code",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.course_code}</span></>;
      },
    },
    {
      title: "Course",
      render: (text, record) => {
        return <>
        <span style={{ fontSize: "10px", }}>{record.course_title}</span>
        </>;
      },
    },
   
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.faculties_name}</span></>;
      },
    },
    {
      title: "# of students",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.number_of_students}</span></>;
      },
    },
    {
      title: "Not Ready",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.not_ready_for_review}</span></>;
      },
    },
    {
      title: "Reviewed",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.mark_as_reviewed}</span></>;
      },
    },
    {
      title: "Not Reviewed",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.ready_for_review}</span></>;
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return <>{check_permissions('students_grades','view') && 
          <Button style={{background:"#b64141"}} type="primary" size="small" onClick={() => navigate('/grading-course/' + btoa(parseInt(record.semester_id)) + '/' + btoa(record.course_id))}>View</Button>
      }</>;
      },
    },


  ];


  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                  style={{
                    width: "110px",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    margin: "3px 0px"
                  }}
                >
                  {item?.value?.slice(0, 2).toUpperCase()}{" "}
                  {item?.start_time} - {item?.end_time}
                </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };


  //  Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = courses_list;
    const filtered = table_data.filter(item =>
      item.course_title.toLowerCase().includes(query.toLowerCase()) || item.course_code.toLowerCase().includes(query.toLowerCase())

    );
    if (query === '') {
      setFilteredData(courses_list);
    } else {
      setFilteredData(filtered);
    }
  };
  const generate_sem_list = (item) => {
    set_semesters_list(item?.semesters);
    set_active_calender_id(item?.id);
    set_active_sem_id(item.semesters[0]?.id);
    SEMESTER_WITH_REGISTERED_COURSE_LIST(item.semesters[0]?.id,"");
  }

  const generate_students_list = (sem_detail) => {
    set_active_sem_id(sem_detail?.id);
    SEMESTER_WITH_REGISTERED_COURSE_LIST(sem_detail?.id,"");
  }

  return (
    <>
    {loader ? (
            <SisLoader />
          ) : (
      <>
          <div className="theme-content-left-head">
            <h3>Grading Courses</h3>
          </div>
        <div className="theme-content-head">
        <div className="sis-tab-menu">
          {calendars_list.length > 0 && (
            <>
              {calendars_list.map((item, index) => (
                <>
                  <>
                    {(active_calender_id == item?.id) ?
                      <>
                        <span className="active" >{item?.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_sem_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          )}
        </div>
       
      </div>
      <div className="sis-tab-sem-menu">
          {semesters_list?.length > 0 &&
            <>
              {semesters_list.map((item, index) => (
                <>
                  <>
                    {(active_sem_id == item.id) ?
                      <>
                        <span className="active" >{item.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_students_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          }
        </div>
            <div className='row'>
              <div className='col-2'>
                <label style={{ fontWeight: "bold", position: "relative", top: "3px", }}>Search Course/course code</label>
              </div>
              <div className='col-2'>
                <Input
                  type="text"
                  style={{ height: "32px" }}
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />

              </div>
              {(check_permissions('students_grades','update') || check_permissions('students_grades','create') )&& 
              <div className='col-3'>
                <Select 
                onChange={(value) => SEMESTER_WITH_REGISTERED_COURSE_LIST(active_sem_id, value)}
                 style={{ width: "100%", marginBottom: "15px" }} 
                 placeholder="--Select Faculty---"
                 value={faculty_id}
                 options={facultytablelist?.map((item) => ({
                   value: item.id,
                   label: <span style={{textTransform:"capitalize"}}>{item.honorific}{" "}{item.name}</span>
                 }))}
                />
              </div>}
            </div>
            {grade_plan && <>
              <h4 style={{ marginBottom:"10px" }}>Grading Plan</h4>
              <div className="row grade_plan_row">
                <div className="col-2">
                  <label>Due date</label>
                  <p>{sisDateFormat(grade_plan?.dua_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading start date</label>
                  <p>{sisDateFormat(grade_plan?.start_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading end date</label>
                  <p>{sisDateFormat(grade_plan?.end_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review start date</label>
                  <p>{sisDateFormat(grade_plan?.review_start_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review end date</label>
                  <p>{sisDateFormat(grade_plan?.review_end_date)}</p>
                </div>
                <div className="col-2">
                  <label>Status</label>
                  <p>{grade_plan_active > 0 ? <span style={{ color:"green" }}>Active</span> : <span style={{ color:"red" }}>Inactive</span>}</p>
                </div>
              </div> </>}
            <div className="sis-tab-content">
              {grade_plan ? <>
                <Table dataSource={filteredData} columns={columns} />
              </> : <>
                <p style={{ color: "red", padding: "10px", textAlign: "center" }}>There is no grading Courses for this semester.</p>
              </>}

            </div>
       </>
        )}
    </>
  );


}

export default Grading;
