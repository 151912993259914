import React, { useEffect, useState } from "react";
import SisLoader from "../../../widgets/loader";
import {
  Button,
  DatePicker,
  notification,
  Popconfirm,
  Select,
  Spin,
} from "antd";
import {
  CALENDARS_LIST,
  PROGRAMS_LIST,
  UPDATE_STUDENT_PROGRAM,
} from "../../../apis/apis";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  FrownOutlined,
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";

dayjs.extend(customParseFormat);

const StudentSingleProgramRow = ({
  set_page_loader,
  student_program_registration_list,
  programme_list,
  entering_year_list,
  sem_list,
  page_loader,
  add_program_view
}) => {
  const [program_update_loader, set_program_update_loader] = useState(false);
  const dateFormat = "MM-DD-YYYY";
  const { id } = useParams();
  const [programme_intended, set_programme_intended] = useState("");
  const [selected_program_concentration, set_selected_program_concentration] =
    useState("");
  const [errors, set_errors] = useState([]);
  const [entering_year, set_entering_year] = useState("");
  const [confer_date, set_confer_date] = useState("");
  const [entering_class, set_entering_class] = useState("");
  const [programme_status, set_programme_status] = useState("");
  const [academic_standing, set_academic_standing] = useState("");
  const [matriculation_semester, set_matriculation_semester] = useState("");
  const [matriculation_date, set_matriculation_date] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [student_type, set_student_type] = useState("");
  const [programme_id, set_programme_id] = useState("");
  const [program_concentration, set_program_concentration] = useState("");

  // const [programme_list, set_programme_list] = useState([]);
  const [semester_list, set_semester_list] = useState([]);
  // const [entering_year_list, set_entering_year_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);

  const calender_change_function = (value) => {
    set_matriculation_semester("");
    const index = entering_year_list.findIndex((item) => item.id === value);
    set_entering_year(value);
    console.log("enter year value dynamic", value);
    set_calender_index(index);
    console.log(index);
    set_semester_list(entering_year_list[index].semesters);
    // entering_year_list[index].semesters.map((item)=>{
    //   console.log(item.title);
    // })
  };

  useEffect(() => {
    // PROGRAMS_LIST_API();
    // LIST_API();
    set_programme_intended(student_program_registration_list?.program_id);
    set_selected_program_concentration(
      student_program_registration_list?.program_concentration
    );
    set_student_type(student_program_registration_list?.student_type);
    set_entering_year(student_program_registration_list?.entering_year);
    set_matriculation_semester(
      parseInt(student_program_registration_list?.matriculation_semester)
    );
    set_matriculation_date(
      student_program_registration_list?.matriculation_date
    );
    set_confer_date(student_program_registration_list?.confer_date);
    set_programme_status(student_program_registration_list?.programme_status);
    set_entering_classification(
      student_program_registration_list?.entering_classification
    );
    set_academic_standing(student_program_registration_list?.academic_standing);
    set_semester_list(student_program_registration_list?.semester_details)

  }, []);

  const UPDATE_API = async () => {
    set_program_update_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("enrollment_id", id);
    FORM_DATA.append("row_id", btoa(student_program_registration_list?.id));
    FORM_DATA.append("programme_status", programme_status);
    const UPDATE_API_RESPONSE = await UPDATE_STUDENT_PROGRAM(FORM_DATA);
    if (UPDATE_API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Program Status Updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_program_update_loader(false);
      set_page_loader(page_loader ? false : true);
    } else {
      notification.open({
        message: "Error!!",
        description: "Student Program Status Not Updated.",
        icon: <FrownOutlined style={{ color: "red" }} />,
      });
      set_program_update_loader(false);
    }
  };

  return (
    <>
      {program_update_loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="row">
            <div className="input-box col-4">
              <label>
                Program/Degree intended<i style={{ color: "red" }}>*</i>
              </label>
              <br></br>
              <Select
                placeholder="--Select Programme--"
                style={{ width: "100%" }}
                onChange={(value) => {
                  set_programme_intended(value);
                }}
                value={programme_intended}
                disabled
              >
                {programme_list?.map((item) => {
                  if (item.status === 1 && item.soft_delete === 0) {
                    return (
                      <>
                        <Select.Option value={item.id}>
                          {item.title}
                        </Select.Option>
                      </>
                    );
                  }
                })}
              </Select>
              {errors?.programme_id && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.programme_id[0]}
                  </span>
                </>
              )}
            </div>
            <div className="input-box col-4">
              <label>
                Program Concentration<i style={{ color: "red" }}>*</i>
              </label>
              <br></br>
              <Select
                placeholder="--Select Programme--"
                style={{ width: "100%" }}
                onChange={(value) => {
                  set_selected_program_concentration(value);
                }}
                disabled={program_concentration?.length === 0}
                value={
                  selected_program_concentration === 0
                    ? ""
                    : selected_program_concentration
                }
              >
                {program_concentration &&
                  program_concentration?.map((item) => {
                    if (item.status === 1 && item.soft_delete === 0) {
                      return (
                        <>
                          <Select.Option value={item.id}>
                            {item.title}
                          </Select.Option>
                        </>
                      );
                    }
                  })}
              </Select>
              {errors?.program_concentration && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.program_concentration[0]}
                  </span>
                </>
              )}
            </div>
            {/* Matriculation Date */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Matriculation Date<i style={{ color: "red" }}>*</i>
                </label>
                <br />

                <DatePicker
                 disabled={student_program_registration_list?.old_program}
                  format={dateFormat}
                  value={matriculation_date && dayjs(matriculation_date)}
                  onChange={(date, dateString) =>
                    set_matriculation_date(dateString)
                  }
                  style={{ width: "100%" }}
                />

                {errors?.matriculation_date && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.matriculation_date[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* Confer Date */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>Confer Date</label>
                <br />

                <DatePicker
                 disabled={student_program_registration_list?.old_program}
                  format={dateFormat}
                  value={confer_date && dayjs(confer_date)}
                  onChange={(date, dateString) => set_confer_date(dateString)}
                  style={{ width: "100%" }}
                />
                {errors?.confer_date && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.confer_date[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <div className="input-box">
                <label>
                  Student Type<i style={{ color: "red" }}>*</i>
                </label>
                <br></br>
                <Select
                 disabled={student_program_registration_list?.old_program}
                  placeholder="-- Student Type--"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    set_student_type(value);
                  }}
                  value={student_type}
                >
                  {["New", "Transferred", "Returning", "Alumni"].map((item) => {
                    return (
                      <>
                        <Select.Option value={item}>{item}</Select.Option>
                      </>
                    );
                  })}
                </Select>
                {errors?.student_type && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_type[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Academic Year<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                 disabled={student_program_registration_list?.old_program}
                  placeholder="--Select Academic Year--"
                  style={{ width: "100%" }}
                  onChange={(value) => calender_change_function(value)}
                  value={
                    entering_year ? parseInt(entering_year) : entering_year
                  }
                  options={entering_year_list?.map((item) => ({
                    value: item.id,
                    calender_id: item.id,
                    label: item.title,
                  }))}
                />
                {errors?.entering_year && (
                  <>
                    <span style={{ color: "red" }}>
                      The academic year field is required.
                      {/* {errors?.academic_year[0]} */}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* Matriculation Semester */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Matriculation Semester<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                 disabled={student_program_registration_list?.old_program || !entering_year}
                  placeholder="--Select Semester--"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    set_matriculation_semester(value);
                  }}
                  value={parseInt(matriculation_semester)}
                  options={semester_list?.map((item) => ({
                    value: parseInt(item.id),
                    label: item.title,
                  }))}
                />

                {errors?.matriculation_semester && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.matriculation_semester[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* Program Status */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Programme Status <i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                 disabled={student_program_registration_list?.old_program}
                  style={{ width: "100%" }}
                  placeholder="Select Program Status"
                  onChange={(value) => {
                    set_programme_status(value);
                  }}
                  value={programme_status}
                  required
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="Inactive">Inactive</Select.Option>
                  <Select.Option value="Completed">Completed</Select.Option>
                  <Select.Option value="Withdraw">Withdraw</Select.Option>
                  <Select.Option value="Dismissed">Dismissed</Select.Option>
                  <Select.Option value="Suspended">Suspended</Select.Option>
                  <Select.Option value="Probation">Probation</Select.Option>
                  <Select.Option value="Deferred">Deferred</Select.Option>
                  <Select.Option value="Readmitted">Readmitted</Select.Option>
                  <Select.Option value="Leave of Absence">
                    Leave of Absence
                  </Select.Option>
                  <Select.Option value="Transferred Out">
                    Transferred Out
                  </Select.Option>
                  <Select.Option value="Discontinued">
                    Discontinued
                  </Select.Option>
                  <Select.Option value="Graduated with Honors">
                    Graduated with Honors
                  </Select.Option>
                  <Select.Option value="Non-Degree Seeking">
                    Non-Degree Seeking
                  </Select.Option>
                  <Select.Option value="Conditional Admission">
                    Conditional Admission
                  </Select.Option>
                </Select>
                {errors?.programme_status && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.programme_status[0]}
                      {/* The program status field is required. */}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* Entering Classification */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Entering Classification<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                 disabled={student_program_registration_list?.old_program}
                  onChange={(value) => {
                    set_entering_classification(value);
                  }}
                  value={entering_classification}
                  style={{ width: "100%" }}
                  placeholder="Select Entering Classifications"
                  required
                >
                  <Select.Option value="undergraduate">
                    Undergraduate
                  </Select.Option>
                  <Select.Option value="graduate">Graduate</Select.Option>
                </Select>
                {errors?.entering_classification && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.entering_classification[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* Academic Standing */}
            <div className="col-2">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Academic Standing <i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                 disabled={student_program_registration_list?.old_program}
                  style={{ width: "100%" }}
                  placeholder="Select Program Status"
                  onChange={(value) => {
                    set_academic_standing(value);
                  }}
                  value={academic_standing}
                  required
                >
                  <Select.Option value="Good Standing">
                    Good Standing
                  </Select.Option>
                  <Select.Option value="Academic Warning">
                    Academic Warning
                  </Select.Option>
                  <Select.Option value="Academic Probation">
                    Academic Probation
                  </Select.Option>
                  <Select.Option value="Continued Probation">
                    Continued Probation
                  </Select.Option>
                  <Select.Option value="Academic Suspension">
                    Academic Suspension
                  </Select.Option>
                  <Select.Option value="Academic Dismissal">
                    Academic Dismissal
                  </Select.Option>
                  <Select.Option value="Conditional Admission">
                    Conditional Admission
                  </Select.Option>
                  <Select.Option value="Reinstated on Probation">
                    Reinstated on Probation
                  </Select.Option>
                  <Select.Option value="Dean’s List/Academic Honors">
                    Dean’s List/Academic Honors
                  </Select.Option>
                  <Select.Option value="Academic Progress Hold">
                    Academic Progress Hold
                  </Select.Option>
                  <Select.Option value="Disciplinary Suspension/Dismissal">
                    Disciplinary Suspension/Dismissal
                  </Select.Option>
                </Select>
                {errors?.academic_standing && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.academic_standing[0]}
                      {/* The program status field is required. */}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          {(student_program_registration_list?.current_program && !(add_program_view)) && (
            <>
                <div className="input-box">
                <Popconfirm
               title={`Are you sure you want to change the program status to "${programme_status}"?`}
                description="The student will no longer be enrolled into this program."
                onConfirm={UPDATE_API}
                onCancel={()=>set_page_loader(page_loader ? false : true)}
                okText="Yes"
                cancelText="No"
              >
                  <Button
                    className="lusisbtn"
                    style={{ width: "200px" }}
                    type="primary"
                  >
                    Update Program Status
                  </Button>
                  </Popconfirm>
                </div>
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default StudentSingleProgramRow;
