import React, { useRef, useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend, PieController, CategoryScale, LinearScale, Title } from 'chart.js';
import { DEMOGRAPHICS_REPORT_GRAPH } from '../../../../apis/apis';
import SisLoader from '../../../../widgets/loader';

Chart.register(ArcElement, Tooltip, Legend, PieController, CategoryScale, LinearScale, Title);

const DemographicReportSummaryGraph = () => {
    const [loader, setLoader] = useState(false);
    const [labels, setLabels] = useState([]);
    const [dataSet, setDataSet] = useState([]);
    const chartRef = useRef(null);

    const fetchDemographicsReport = async () => {
        setLoader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);

        try {
            const API_RESPONSE = await DEMOGRAPHICS_REPORT_GRAPH(FORM_DATA);
            if (API_RESPONSE?.data?.status) {
                setLabels(API_RESPONSE.data.labels);
                setDataSet(API_RESPONSE.data.data_set);
            }
        } catch (error) {
            console.error("Error fetching demographics report:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchDemographicsReport();
    }, []);

    useEffect(() => {
        if (labels.length === 0 || dataSet.length === 0) {
            return;
        }

        const ctx = chartRef.current.getContext('2d');

        const data = {
            labels: labels,
            datasets: [
                {
                    data: dataSet,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FF0000',
                        '#50C878',
                        '#9966FF',
                        '#FF9F00',
                        '#C9CBCF',
                        '#FF11FF'
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FF0000',
                        '#50C878',
                        '#9966FF',
                        '#FF9F00',
                        '#C9CBCF',
                        '#FF11FF'
                    ]
                }
            ]
        };

        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            boxWidth: 12,
                            font: {
                                size: 12
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Summary of Demographics Report'
                    }
                }
            }
        };

        const myPieChart = new Chart(ctx, config);

        return () => {
            myPieChart.destroy();
        };
    }, [labels, dataSet]);

    return (
        <div>
            {loader ? (
                <SisLoader />
            ) : (
                <>
                    {labels.length > 0 && dataSet.length > 0 ? (
                        <>
                            <h3>Visualization Summary of Demographic Report</h3>
                            <br/>
                            <div style={{ width: '350px', margin: '0 auto' }}>
                                <canvas ref={chartRef}></canvas>
                            </div>
                        </>
                    ) : (
                        <p style={{ textAlign: "center", color: "red" }}>No Demographics Report Found.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default DemographicReportSummaryGraph;
